import { useContext } from "react";
import { UserContext } from "../context/UserContext";

export const useAuthContext = () => {

    const context = useContext(UserContext)

    if(!context) {
        throw Error('the user context must be used inside the context provider')
    }

    return context
}