import React, { useContext, useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Col, Container, Row, Button, Form, InputGroup, Table } from "react-bootstrap";
import { checkIcon, giftIcon, invoiceIcon, phoneIcon, truckIcon, walletIcon } from '../constants/grabIcons'
import { BASE_URL , NEW_BASE_URL } from "../constants/serv";

import { useAuthContext } from "../customHooks/useAuthContext";
import { useInvoice } from "../customHooks/useInvoice";

import { SHIPPING } from "../shippingData";

import Login from "./Login";
import { CartContext } from "../context/CartContext";
import { useDecreaseQty } from "../customHooks/useDecreaseQty";



const Checkout = () => {

    const navigate = useNavigate()
    const { decreaseQty } = useDecreaseQty()

    const [triggerToggle, setTriggerToggle] = useState(true)
    const [shippings, setProducts] = useState([])
    useEffect(() => {
        const fetchProducts = async () => {
            const res = await fetch(`${NEW_BASE_URL}/api/shipping`)
            console.log(res)
            var json = await res.json()
            json=json.data
            //console.log(json)

            if (res.ok) {
                setProducts(json)
            } else {
                console.log('error occurred', res)
            }
        }

        fetchProducts()
    }, [triggerToggle])


    
        
    const { itemsInCart, dispatch } = useContext(CartContext);
    var  user = JSON.parse(localStorage.getItem('user'));
    const { getInvoice } = useInvoice()
    const { name, phoneNo, city, address, building, floor, apt, landmark, workAddress } = user

    const [loading, setLoading] = useState(false)
    const [btnText, setBtnText] = useState('CONFIRM ORDER')

    const checkedOutCart = itemsInCart.filter(i => i.count !== 0)

    // calculate subtotal & discount
    let subtotalValue = 0
    let discountValue = 0
    checkedOutCart.forEach(i => {
        subtotalValue += i.count * i.price
        discountValue += i.count * i.discount
    })

    // calculate shipping
    const shippingInfo = shippings.length > 0 ? shippings.find(o => o.name === city) : 0
    const deliveryPrice = shippings.length > 0 ? shippingInfo.value : 0

    //const [promocodes, setPromocodes] = useState()
    const [promoEntry, setPromoEntry] = useState('')
    const [promoValid, setPromoValid] = useState(false)
    const [promocodeValue, setPromocodeValue] = useState(0)
    const [promoFeedback, setPromoFeedback] = useState('')

    let PromoData = null

    const checkPromo = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        const res = await fetch(`${BASE_URL}/api/promocodes/all`)
        const json = await res.json()
        console.log(json)

        if (res.ok) {
            PromoData = json.find(i => i.code === promoEntry)
        }

        if (PromoData) {
            setPromoValid(true)
            setPromocodeValue(PromoData.value / 100 * (subtotalValue - discountValue))
            setPromoFeedback('Discount applied!')
        } else {
            setPromoValid(false)
            setPromocodeValue(0)
            setPromoFeedback('Promocode is invalid!')
        }
        console.log('promodata', PromoData)
    }

    // Grand Total
    let grandTotal = subtotalValue - discountValue - promocodeValue + deliveryPrice

    const placeOrder = async () => {

        setLoading(true)
        setBtnText('Confirming...')
        const dataSum = { subtotalValue, discountValue, promocodeValue, deliveryPrice }
        const invoice = getInvoice(dataSum)

        const { orderNo, orderDate, orderTime, orderedBy, total, grandTotal, paymentMethod, status, purchasedProducts } = invoice
       
        const config = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ orderNo, orderDate, orderTime, orderedBy, total, grandTotal, paymentMethod, status, purchasedProducts }),
        }
        const res = await fetch(`${NEW_BASE_URL}/api/orders`,config)
        
        await res.json()

        if (res.ok) {
            dispatch({ type: 'EMPTY_CART' }, 0)
            purchasedProducts.forEach(item => decreaseQty(item))
            //decreaseQty(purchasedProducts)
            //const ApplyChangeToStock = await fetch(`${BASE_URL}/api/products/`)
            
            navigate('/checkout/completed', { state: { date: orderDate, time: orderTime, orderID: orderNo } })
        }

    }
    return (
        <Container className="checkout-page">
            {
                user &&
                <div >
                    <Row className="center">
                        <h1 className="title">CHECKOUT</h1>
                    </Row>
                    <Row>
                        <Col className="col-12 col-md-7">
                            <div className="card shadow-light-out">
                                <h5><strong>{invoiceIcon} Order Summary</strong></h5>
                                {checkedOutCart.map(item => {
                                    return (
                                        <Row key={item.id} className="text-start item-bound" >
                                            <Col className="col-12 col-lg-7 overflow-container">
                                                <small className="overflow-cut">{item.name}</small>
                                            </Col>
                                            <Col className="col-6 col-lg-3">
                                                <small>EGP </small><strong>{item.price - item.discount}</strong>
                                            </Col>
                                            <Col className="col-6 col-lg-2">
                                                <small>QTY: </small><strong>{item.count}</strong>
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </div>
                            <div className="card shadow-light-out">
                                <h5><strong>{truckIcon} Delivery Info</strong></h5>
                                <Row className="item-bound">
                                    <Col><small>To: <strong>{name}</strong></small></Col>
                                    <Col><small>{phoneIcon} <strong>{phoneNo}</strong></small></Col>
                                </Row>
                                <Row>
                                    <Col className="col-12"><small>City: <strong>{city} </strong></small></Col>
                                    <Col className="col-12"><small>Address: <strong>{address} </strong></small></Col>
                                    <Col className="col-12 col-sm-6"><small>Building: <strong>{building} </strong></small></Col>
                                    <Col className="col-12 col-sm-3"><small>Floor: <strong>{floor} </strong></small></Col>
                                    <Col className="col-12 col-sm-3"><small>Apt: <strong>{apt} </strong></small></Col>
                                    <Col className="col-12"><small>NearbyLandmark: <strong>{landmark} </strong></small></Col>
                                    <Col className="col-12"><small>Work Address?  {workAddress ? <strong>Yes</strong> : <strong>No</strong>} </small></Col>
                                </Row>
                                <br />
                                <Row>
                                    <small className="grey text-end">
                                        Different Address? <Link to={{ pathname: `/user/:${user._id}` }} className="link-style">Edit</Link>
                                    </small>
                                </Row>
                            </div>
                        </Col>
                        <Col className="col-12 col-md-5">
                            <div className="card shadow-light-out">
                                <h5><strong>{walletIcon} Payment Method</strong></h5>
                                <Form>
                                    <Row>
                                        <Col className="col-12">
                                            <Form.Check
                                                type="switch" checked readOnly={true}
                                                label="Cash on Delivery"
                                                id="custom-switch"
                                            />
                                        </Col>
                                        <Col className="col-12">
                                            <Form.Check
                                                type="switch" disabled
                                                label="Credit/Debit Card (currently unavailable)"
                                                id="disabled-custom-switch"
                                            />
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                            <div className="card shadow-light-out">
                                <h5><strong>{giftIcon} Promocode</strong></h5>
                                <Form className="promocode-sec" onSubmit={checkPromo} noValidate validated={promoValid}>
                                    <small> Apply Promocode or gift code here</small>
                                    <InputGroup className="py-1">
                                        <Form.Control placeholder="Promocode" aria-label="Promocode" name="promocodeInput"
                                            aria-describedby="basic-addon2" className="input" isInvalid={Boolean(promoValid)}
                                            value={promoEntry} onChange={(e) => setPromoEntry(e.target.value)}
                                        />
                                        <Button id="btn-promo" type="submit">
                                            APPLY
                                        </Button>
                                    </InputGroup>
                                    <p className={promoValid ? "valid-input-sm" : "invalid-input-sm"}>{promoFeedback}</p>
                                </Form>
                            </div>
                            <Row className="sum-sec">
                                <Table>
                                    <tbody className="body-table">
                                        <tr>
                                            <td><p>Subtotal:</p></td>
                                            <td><p className="text-end">EGP <strong>{subtotalValue}</strong></p></td>
                                        </tr>
                                        {discountValue === 0 ? <></> :
                                            <tr>
                                                <td><p>Discount:</p></td>
                                                <td><p className="text-end">EGP <strong className="sec-txt">- {discountValue}</strong></p></td>
                                            </tr>
                                        }
                                        {promoValid ?
                                            <tr>
                                                <td><p>Promocode discount</p></td>
                                                <td><p className="text-end">EGP <strong className="sec-txt">- {promocodeValue}</strong></p></td>
                                            </tr>
                                            : <></>
                                        }
                                        <tr>
                                            <td><p>Shipping:</p></td>
                                            <td><p className="text-end">EGP <strong>{deliveryPrice}</strong></p></td>
                                        </tr>
                                        <tr>
                                            <td><p>Estimated Total:</p></td>
                                            <td><p className="text-end">EGP <strong>{grandTotal}</strong></p></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="sticky-checkout shadow-light-out">
                        <p className="center">Grand Total: <strong>EGP {grandTotal}</strong></p>
                        <Button id="btn-pri-top-left" className="checkout-btn"
                            onClick={placeOrder} disabled={loading}>
                            {btnText} {checkIcon}
                        </Button>
                    </Row>
                </div>
            }
            {user === null ?
                <div>
                    <Login />
                </div>
                : <></>
            }
        </Container>
    )
}

export default Checkout;