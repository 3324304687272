import { useContext } from "react";
import { useAuthContext } from "./useAuthContext";
//import { ShopContext } from "../context/ShopContext";

import { v4 as uuid } from "uuid";
import { CartContext } from "../context/CartContext";

export const useInvoice = () => {

    const { itemsInCart } = useContext(CartContext);
      var  user = JSON.parse(localStorage.getItem('user'));
    const { _id, name, phoneNo, email, city, address, building, floor, apt, landmark, workAddress } = user

    const checkedOutCart = itemsInCart.filter(i => i.count !==0)

    // ----->> INVOICE CREATION
    const getInvoice =  (totals) => {

        const { subtotalValue, discountValue, promocodeValue, deliveryPrice } = totals
        const grandTotal = subtotalValue + deliveryPrice - discountValue - promocodeValue

        const getDate = new Date().toDateString()
        const getTime = new Date().toLocaleTimeString()

        const uniqueID = uuid().slice(0, 8).toUpperCase();
        // console.log('id',uniqueID)

        const orderInvoice = {
            orderNo: uniqueID,
            orderDate: getDate,
            orderTime: getTime,
            orderedBy: {
                userID: _id,
                userName: name,
                userPhone: phoneNo,
                userEmail: email,
                city: city,
                address: address,
                building: building,
                floor: floor,
                apt: apt,
                landmark: landmark,
                workAddress: workAddress
            },
            total: {
                subtotal: subtotalValue,
                discountValue: discountValue,
                discountPromo: promocodeValue,
                shipping: deliveryPrice
            },
            grandTotal: parseFloat(grandTotal).toFixed(2),
            paymentMethod: "Cash on Delivery",
            status: "in progress",
            purchasedProducts: checkedOutCart
        }
        // console.log(orderInvoice)

        return orderInvoice
    }

    return {getInvoice}

}