// fontAwesomeIcons ----incomplete

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTrash, faCheck, faXmark, faCartShopping, faBarsStaggered, faUser,
    faEnvelope, faPhone, faCartPlus, faTruckFast, faMedal, faLocationDot, faSocks,
    faRightLeft, faRightFromBracket, faGift, faFileInvoiceDollar,
    faCommentsDollar,
} from '@fortawesome/free-solid-svg-icons'
import { faSquareFacebook, faTiktok, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'


// General Usage
export const deleteIcon = <FontAwesomeIcon icon={faTrash} />
export const checkIcon = <FontAwesomeIcon icon={faCheck} />
export const xIcon = <FontAwesomeIcon icon={faXmark} />
export const addToCartIcon = <FontAwesomeIcon icon={faCartPlus} />
export const logoutIcon = <FontAwesomeIcon icon={faRightFromBracket} />
export const giftIcon = <FontAwesomeIcon icon={faGift} />
export const truckIcon = <FontAwesomeIcon icon={faTruckFast} />
export const walletIcon = <FontAwesomeIcon icon={faCommentsDollar} />
export const invoiceIcon = <FontAwesomeIcon icon={faFileInvoiceDollar} />

// Navbar
export const cartIcon = <FontAwesomeIcon icon={faCartShopping} size="lg" />
export const barsStagIcon = <FontAwesomeIcon icon={faBarsStaggered} />
export const userIcon = <FontAwesomeIcon icon={faUser} />

// About page
export const medalIcon = <FontAwesomeIcon icon={faMedal} />
export const locationIcon = <FontAwesomeIcon icon={faLocationDot} />
export const socksIcon = <FontAwesomeIcon icon={faSocks} />
export const exchangeIcon = <FontAwesomeIcon icon={faRightLeft} />

// Footer
export const tiktokIcon = <FontAwesomeIcon icon={faTiktok} />
export const facebookIcon = <FontAwesomeIcon icon={faSquareFacebook} />
export const instagramIcon = <FontAwesomeIcon icon={faInstagram} />
export const whatsappIcon = <FontAwesomeIcon icon={faWhatsapp} />
export const mailIcon = <FontAwesomeIcon icon={faEnvelope} />
export const phoneIcon = <FontAwesomeIcon icon={faPhone} />