export const SHIPPING = [
    {
        id: 1,
        region: 'Cairo - القاهرة',
        pricesLg: [{
            delivery: 62.7,
            exchange: 74.1,
            return: 74.1
        }]
    },
    {
        id: 2,
        region: 'Giza - الجيزة',
        pricesLg: [{
            delivery: 62.7,
            exchange: 74.1,
            return: 74.1
        }]
    },
    {
        id: 3,
        region: 'Alexandria - الاسكندرية',
        pricesLg: [{
            delivery: 68.4,
            exchange: 79.8,
            return: 74.1
        }]
    },
    {
        id: 4,
        region: 'Beheira - البحيرة',
        pricesLg: [{
            delivery: 68.4,
            exchange: 79.8,
            return: 74.1
        }]
    },
    {
        id: 5,
        region: 'Assiut - اسيوط',
        pricesLg: [{
            delivery: 85.5,
            exchange: 96.9,
            return: 74.1
        }]
    },
    {
        id: 6,
        region: 'Fayyum - الفيوم',
        pricesLg: [{
            delivery: 85.5,
            exchange: 96.9,
            return: 74.1
        }]
    },
    {
        id: 7,
        region: 'Beni Suef - بني سويف',
        pricesLg: [{
            delivery: 85.5,
            exchange: 96.9,
            return: 74.1
        }]
    },
    {
        id: 8,
        region: 'Al Minya - المنيا',
        pricesLg: [{
            delivery: 85.5,
            exchange: 96.9,
            return: 74.1
        }]
    },
    {
        id: 9,
        region: 'Sohag - سوهاج',
        pricesLg: [{
            delivery: 85.5,
            exchange: 96.9,
            return: 74.1
        }]
    },
    {
        id: 10,
        region: 'New Valley - الوادي الجديد',
        pricesLg: [{
            delivery: 108.3,
            exchange: 119.7,
            return: 74.1
        }]
    },
    {
        id: 11,
        region: 'South Sinai - جنوب سيناء',
        pricesLg: [{
            delivery: 108.3,
            exchange: 119.7,
            return: 74.1
        }]
    },
    {
        id: 12,
        region: 'North Coast - الساحل الشمالي',
        pricesLg: [{
            delivery: 96.9,
            exchange: 108.3,
            return: 74.1
        }]
    },
    {
        id: 13,
        region: 'Aswan - اسوان',
        pricesLg: [{
            delivery: 96.9,
            exchange: 108.3,
            return: 74.1
        }]
    },
    {
        id: 14,
        region: 'Luxor - الاقصر',
        pricesLg: [{
            delivery: 96.9,
            exchange: 108.3,
            return: 74.1
        }]
    },
    {
        id: 15,
        region: 'Qena - قنا',
        pricesLg: [{
            delivery: 96.9,
            exchange: 108.3,
            return: 74.1
        }]
    },
    {
        id: 16,
        region: 'Marsa Matrouh - مرسى مطروح',
        pricesLg: [{
            delivery: 96.9,
            exchange: 108.3,
            return: 74.1
        }]
    },
    {
        id: 17,
        region: 'Red Sea - البحر الأحمر',
        pricesLg: [{
            delivery: 96.9,
            exchange: 108.3,
            return: 74.1
        }]
    },
    {
        id: 18,
        region: 'dakahlia - الدقهلية',
        pricesLg: [{
            delivery: 74.1,
            exchange: 85.5,
            return: 74.1
        }]
    },
    {
        id: 19,
        region: 'Sharqiah - الشرقية',
        pricesLg: [{
            delivery: 74.1,
            exchange: 85.5,
            return: 74.1
        }]
    },
    {
        id: 20,
        region: 'Menufiya - المنوفية',
        pricesLg: [{
            delivery: 74.1,
            exchange: 85.5,
            return: 74.1
        }]
    },
    {
        id: 21,
        region: 'Kafr ELSheikh - كفر الشيخ',
        pricesLg: [{
            delivery: 74.1,
            exchange: 85.5,
            return: 74.1
        }]
    },
    {
        id: 22,
        region: 'Gharbiah - الغربية',
        pricesLg: [{
            delivery: 74.1,
            exchange: 85.5,
            return: 74.1
        }]
    },
    {
        id: 23,
        region: 'Qalyubiah - القليوبية',
        pricesLg: [{
            delivery: 74.1,
            exchange: 85.5,
            return: 74.1
        }]
    },
    {
        id: 24,
        region: 'Portsaid - بورسعيد',
        pricesLg: [{
            delivery: 74.1,
            exchange: 85.5,
            return: 74.1
        }]
    },
    {
        id: 25,
        region: 'Ismailia - الاسماعيلية',
        pricesLg: [{
            delivery: 74.1,
            exchange: 85.5,
            return: 74.1
        }]
    },
    {
        id: 26,
        region: 'Demmiette - دمياط',
        pricesLg: [{
            delivery: 74.1,
            exchange: 85.5,
            return: 74.1
        }]
    },
    {
        id: 27,
        region: 'Suez - السويس',
        pricesLg: [{
            delivery: 74.1,
            exchange: 85.5,
            return: 74.1
        }]
    }
]