import { Row, Col, Button, Form } from "react-bootstrap"
import { useEffect, useState } from "react"

import { BASE_URL } from "../constants/serv"
import { NEW_BASE_URL } from "../constants/serv"

import axios from 'axios'

const WebsiteStyling = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [triggerLoad, setTriggerLoad] = useState(true)
    const [uploadSuccess, setUploadSuccess] = useState(false)
    const [uploadFail, setUploadFail] = useState(false)
    const [uploadErr, setUploadErr] = useState('')
    const [file, setFile] = useState(null)
    const [noFile, setNoFile] = useState(true)
    const [img, setImg] = useState()


    useEffect(() => {
        const getImages = async () => {
            await axios.get(`${NEW_BASE_URL}/api/hero-image`)
                .then(res => {
                    setImg(res.data)
                    //console.log(res)
                    //console.log(res.data.img)
                })
                .catch(err => console.log('err hero get', err))
        }

        getImages()

    }, [triggerLoad])

    const handleUpload = () => {
        //e.preventDefault()
        //e.stopPropagation()
        setIsLoading(true)
        const formdata = new FormData()
        formdata.append('image', file)

        //console.log(formdata)
        //console.log(file)

        axios.post(`${NEW_BASE_URL}/api/hero-image`, formdata)
            .then(res => {
                if (res.status === 200) {
                    setTriggerLoad(!triggerLoad)
                    setIsLoading(false)
                    setUploadSuccess(true)
                }
                //console.log(res)
            })
            .catch(err => {
                //console.log('err', err.response)
                setUploadFail(true)
                setUploadErr(err.response.statusText)
            })
    }

    return (
        <div>
            <div className='plain-wrapper'>
                <div className='plain-wrapper'>
                    <h4><strong>Website Media and Marketing</strong></h4>
                </div>
            </div>
            <div className='plain-wrapper'>
                <div className='border-wrapper'>
                    <Row className="plain-wrapper">
                        <Col className='col-12 col-lg-6'>
                            <h6><strong>Hero Image (main)</strong></h6>
                        </Col>
                        <Col className='col-12 col-lg-6'>
                            <Form formEncType="multipart/form-data" method="post">
                                <Row>
                                    <Col className="col-12 col-lg-7">
                                        <Form.Control type="file" name="heroFile" size='sm' formEncType="multipart/form-data"
                                            onChange={e => {
                                                setNoFile(false)
                                                setFile(e.target.files[0])
                                                //console.log(e.target.files)
                                            }}
                                        />
                                        <small className="tiny-txt grey">Note: Preffered image dimension: 700 x 450px</small>
                                    </Col>
                                    <Col className="col-12 col-lg-5">
                                        {!uploadSuccess && !uploadFail ?
                                            <Button variant="success" disabled={noFile || isLoading} size="sm"
                                                onClick={handleUpload}>
                                                Upload New +
                                            </Button>
                                            : uploadFail ?
                                            <small className="red">{uploadErr}<br />Please try again later.</small>
                                            :
                                            <small className="green">Successfully Uploaded</small>
                                        }
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    <div>
                        <small className="grey plain-wrapper">currently used <small className="tiny-txt">(not displayed in real dimesions)</small></small>
                        <div className='border-wrapper'>
                            <div>
                                {img &&
                                    <img alt="hero" src={img} width={250} />
                                }
                            </div>
                            <br />
                        </div>

                    </div>
                </div>
                <div className='border-wrapper under-const hidden'>
                    <Row>
                        <Col className='col-6'>
                            <h6><strong>Hero Image (secondary)</strong></h6>
                            <small className="grey">currently used </small>
                        </Col>
                        <Col className='col-6 text-end'>
                            <Button variant='outline-secondary' size='sm'>Change</Button>
                        </Col>
                    </Row>
                    <div>
                        <div className='border-wrapper'>
                            <p>image...</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='plain-wrapper under-const'>
                <div className='border-wrapper'>
                    <Row>
                        <Col className='col-6'>
                            <h6><strong>Notice Banner Text</strong></h6>
                        </Col>
                        <Col className='col-6 text-end'>
                            <Button variant='outline-secondary' size='sm'>Change</Button>
                        </Col>
                    </Row>
                    <Row>
                        <ul className="list">
                            <li>Text 1:</li>
                            <li>Text 2:</li>
                            <li>Text 3:</li>
                        </ul>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default WebsiteStyling