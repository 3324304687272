import React, { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";

import { BASE_URL } from "../constants/serv";
import ReactPlayer from "react-player";

const PreviewSection = ({ props }) => {

  const { sku, previewImages, previewVideo } = props

  let images = Object.values(previewImages);
  let videos = Object.values(previewVideo);

  const [show, setShow] = useState(false);
  const [image, setImage] = useState()

  const openModal = (img) => {
    setImage(img)
    setShow(true)
  }
  return (
    <div className="preview-section">
      <h6><strong>PREVIEW</strong></h6>
      {(images[0] || videos[0]) ?
        <Row>
          <Col className="col-12 col-lg-7 margin-0 padding-0 pvw-img-col-wrap">
            {images &&
              <Row>
                {images.map(img => (
                  <Col key={img.uuid} className="col-6 margin-0 padding-0 col-cont-container">
                    <div className="img-container">
                      {img && <img alt="preview" src={img.original_url}
                        onClick={() => openModal(img.original_url)} />}
                    </div>
                  </Col>
                ))}
              </Row>
            }
          </Col>

          {image &&
            <Modal show={show} onHide={() => setShow(false)} size="lg" className="lightbox-modal">
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <img alt="preview" src={image}
                  className="shadow-light-out" />
              </Modal.Body>
            </Modal>
          }
          <Col className="col-12 col-lg-5">
            {videos[0] &&
              <Row>
                {videos[0] ?
                  <Col className="preview-vid">
                    <div className="review-wrapper">
                      <ReactPlayer url={videos[0].original_url}
                        className="video-preview" controls={true} width="100%" height="100%" muted />
                    </div>
                  </Col>
                  :
                  <></>
                }
              </Row>
            }
          </Col>
        </Row>
        :
        <small className="py-2 grey">No preview images</small>
      }
    </div>
  )
}

export default PreviewSection