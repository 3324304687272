import { Container, Row, Col, Button } from "react-bootstrap"
import { useNavigate, useLocation } from "react-router-dom";

import checkIcon from '../assets/images/check-circle-green.png'

const OrderFeedback = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const { date, time, orderID } = location.state

    return (
        <Container className="center py-2">
            <Row className="py-5">
                <div><img alt="successful" src={checkIcon} width={65} /></div>
                <h3 className="successful py-3">Order Successfully Placed!</h3>
            </Row>
            <div className="basic-card shadow-light-out py-5">
                <Row className="py-1">
                    <h5><strong>Thank you for choosing CANALWALK!</strong></h5>
                </Row>
                <Row className="py-3">
                    <p>You OrderID is: <strong>{orderID}</strong></p>
                    <p>Order Date: {date} - {time}</p>
                </Row>
                <Row className="py-3 grey">
                    <p>Estimated delivery time is 5 working days</p>
                    <p>For more info please contact us</p>
                </Row>
                <Row className="py-3">
                    <Col>
                        <Button id="btn-sec-top-left" onClick={() => navigate('/')}>
                            CONTINUE BROWSING
                        </Button>
                    </Col>
                </Row>
            </div>

            <Row className="py-5"></Row>
        </Container>
    )
}

export default OrderFeedback