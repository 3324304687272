import React from "react";
import Slider from "react-slick";

function NoticeBanner() {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: true
  };

  var notice1 = ''
  var notice2 = 'HURRY UP!! SHOP NOW!'
  var notice3 = null

  return (
    <div className="slider-container banner hidden">
      <Slider {...settings}>
        { notice1 ?
        <div>
            <small><strong>{notice1}</strong></small>
        </div> : null
        }
        { notice2 !== null ?
        <div>
            <small><strong>{notice2}</strong></small>
        </div> : null
        }
        { notice3 ?
        <div>
            <small><strong>{notice3}</strong></small>
        </div> : null
        }
      </Slider>
    </div>
  );
}

export default NoticeBanner;
