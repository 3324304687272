import { Container, Row, Col } from "react-bootstrap"
import loadingPlaceholder from '../assets/images/walking-shoes.gif'

const LoadingComponent = () => {

    return(
        <Container className="loading-cont">
            <Row>
                <Col className="col-12 col-md-4"></Col>
                <Col className="col-12 col-md-4 center">
                    <img alt="walking-shoes" src={loadingPlaceholder} width={150} className=""/>
                    <br/>
                    <p className="py-4">Loading...</p>
                </Col>
                <Col className="col-12 col-md-4"></Col>
            </Row>
        </Container>
    )
}

export default LoadingComponent