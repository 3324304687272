import axios from "axios"
import { useState } from "react"
import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap"
import { NEW_BASE_URL } from "../constants/serv"
import { useSKUGenerator } from "../customHooks/useSKUGenerator"
import { useNavigate } from "react-router-dom"

const AddNewHappyClientForm = () => {

   
    const navigate = useNavigate()
    // UI feedback
    const [loading, setLoading] = useState(false)
    const [isApproved, setIsApproved] = useState(false)

    const [avail, setAvail] = useState(true)
    const [feat, setFeat] = useState(false)
    //const [productImagesArr, setProductImagesArr] = useState([{}])


    const [name, setName] = useState("")
    const [message, setMessage] = useState("")

  


    const handleSaveProduct = (e) => {
        e.preventDefault()
        setLoading(true)

        // preparing the product object to send to db
       
        if (message.length === 0) {
            setMessage("MessageLess")
        }
        if (name.length === 0) {
            setName("NoName")
        }

        // generate SKU
        const data = { name, message }

        const saveProduct = () => {

            const formData = new FormData();
            formData.append('name', name)
            formData.append('message', message)
        
          
            
            // }
            // post to db
            axios.post(`${NEW_BASE_URL}/api/happy-clients`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(res => {
                console.log('res', res)
                setLoading(false)
                /*
                if (res.status === 200) {
                    setTriggerLoad(!triggerLoad)
                }
                */
                navigate('/admin/homepage')
            }).catch(err => console.log('err', err))


        }

        saveProduct()
    }

    return (
        <Container className="py-5">
            <Row>
                <Col className='col-12'>
                    <h4><strong>Add New Client</strong></h4>
                </Col>
            </Row>
            <Row>
                <Form encType="multipart/form-data">
                    <Row className="py-1">
                        <strong>Basic Info</strong>
                        <small className="grey tiny-txt">Please fill in all fields</small>
                    </Row>
                    <Row>
                        <Col className="col-12 col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Control type="text" name="name" placeholder="User Name"
                                    onChange={e => setName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12 col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Control type="text" name="message" placeholder="Message"
                                    onChange={e => setMessage(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
              
      
                    <br />
    
                  
                  
         
                    <div>

                    </div>
                    <div>


                    </div>
                    <Row>
                        <div className="text-end">
                            <Button variant="success" //disabled={loading}
                                onClick={e => handleSaveProduct(e)}>
                                Save 
                            </Button>
                        </div>
                    </Row>
                </Form>
            </Row>
        </Container>
    )
}

export default AddNewHappyClientForm