import { useEffect, useState } from "react"
import { Row, Col, Button, Modal } from "react-bootstrap"
import { BASE_URL } from "../constants/serv"

//icons
import { deleteIcon } from "../constants/grabIcons"

const Requests = () => {

    const [loading, setLoading] = useState(false)
    const [triggerToggle, setTriggerToggle] = useState(true)

    const [requests, setRequests] = useState()
    useEffect(() => {
        const fetchRequests = async () => {
            const res = await fetch(`${BASE_URL}/api/requests/all`)
            const json = await res.json()

            if (res.ok) {
                setRequests(json)
            }
        }

        fetchRequests()
    }, [triggerToggle])

    const [delModalShow, setDelModalShow] = useState(false) //delete request modal
    const [requestToDel, setrequestToDel] = useState(null)

    const openModalDel = (request) => {
        setrequestToDel(request)
        setDelModalShow(true)
    }

    const handleDelete = (requests) => {
        setLoading(true)
        const id = requests._id
        const deleterequests = async () => {
            const response = await fetch(`${BASE_URL}/api/requests/${id}`, {
                method: 'DELETE'
            })
            await response.json()

            if (response.ok) {
                setDelModalShow(false)
                setLoading(false)
                setTriggerToggle(!triggerToggle)
            }
        }

        deleterequests()
    }

    return (
        <div>
            <div className='plain-wrapper'>
                <div className='plain-wrapper'>
                    <h4><strong>Requests Review</strong></h4>
                    <small className='grey'>All {requests && requests.length} requests</small>
                </div>

                <Row>
                    {requests && requests.map(rec => (
                        <Col className='col-12 col-lg-4' key={rec._id}>
                            <div className='border-wrapper'>
                                <Row>
                                    <Col className="col-9">
                                        <ul className='list p-0'>
                                            <li><small>Name: <strong>{rec.userName}</strong></small></li>
                                            <li><small>PhoneNo: <strong>{rec.phone}</strong></small></li>
                                            <li><small>Mail: <strong>{rec.mail}</strong></small></li>
                                            <br />
                                            <li><strong>Request Info</strong></li>
                                            <li><small>Required requests: <strong>{rec.requestsName}</strong></small></li>
                                            <li><small>brand: <strong>{rec.brand}</strong></small></li>
                                            <li><small>color: <strong>{rec.color}</strong></small></li>
                                            <li><small>size: <strong>{rec.size}</strong></small></li>
                                            <li><small>comment: <strong>{rec.comment}</strong></small></li>
                                            <li><small>Contact Me: <strong>{rec.contactMe ? <>Yes</> : <>No</>}</strong></small></li>
                                        </ul>
                                    </Col>
                                    <Col className=" col-3 text-end">
                                        <Button variant="outline-danger" size="sm" disabled={loading}
                                            onClick={() => openModalDel(rec)}>
                                            {deleteIcon}
                                        </Button>
                                    </Col>
                                    <Modal show={delModalShow} onHide={() => setDelModalShow(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Delete request</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <p>Are you sure you want to delete this request?</p>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" size="sm" onClick={() => setDelModalShow(false)}>
                                                Close
                                            </Button>
                                            <Button variant="danger" size="sm" onClick={() => handleDelete(requestToDel)}>
                                                Delete request
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Row>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
            <br />
            <Row>
                <div className='center hidden'>
                    <Button variant='outline-secondary' disabled>Load More</Button>
                </div>
            </Row>
        </div>
    )
}

export default Requests