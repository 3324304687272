import React from "react";
import { useNavigate } from "react-router-dom";

const CategoryCard = ({cardImage, cardTitle}) => {

    const navigate = useNavigate()
    let filterChoice

    switch(cardTitle) {
        case "MEN":
            filterChoice = "men";
        break;
        case "WOMEN":
            filterChoice = "women";
        break;
        case "SNEAKERS":
            filterChoice = "sneakers";
        break;
        case "SOCKS":
            filterChoice = "socks";
        break;
        case "SHOES CREASE PROTECTORS":
            filterChoice = "crease protectors";
        break;
        default:
            filterChoice = 'all'
    }

    return (
        <div className="category-card" onClick={()=> navigate(`/products/:${filterChoice}` , {state: {filterValue: filterChoice}})}>
            <div className="img-wrapper">
                <img alt="card" className='shadow-light-out' src={cardImage} />
            </div>
            <div className="title-wrapper shadow-light-out">
                <strong>{cardTitle}</strong>
            </div>
        </div>
    )
}

export default CategoryCard;