import React, { useEffect, useState } from "react";

import UpdateUserInfoForm from "../components/EditUserData";

import { useAuthContext } from "../customHooks/useAuthContext";
import { useLogout } from "../customHooks/useLogout";
// import { useDeleteUser } from "../customHooks/useDeleteUser";

import { Container, Row, Col, Button, Modal } from "react-bootstrap";

import { BASE_URL } from "../constants/serv";
import { NEW_BASE_URL } from "../constants/serv";
import { phoneIcon, mailIcon, deleteIcon, logoutIcon } from '../constants/grabIcons'

const UserDetails = () => {

    // getting the data of user (user context)
    var  user = JSON.parse(localStorage.getItem('user'));
  
    const { _id, name, phoneNo, email, city, address, building, floorNo, apt, landmark, workAddress } = user

    let userID = _id

    const [orders, setOrders] = useState()

    // fetching the user orders
    useEffect(() => {
        const fetchOrders = async () => {
            const response = await fetch(`${BASE_URL}/api/orders/ordersbyuser/${userID}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json'}
            })

            const json = await response.json()

            if(response.ok) {
                setOrders(json)
            }

        }

        fetchOrders()

    },[userID])


    // logout user
    const { logout } = useLogout()
    // const { deleteUser } = useDeleteUser()

    const handleLogout = () => {
        logout()
    }

    // handle delete user
    // const [show, setShow] = useState(false);
    // const handleModalClose = () => setShow(false);
    // const handleModalShow = () => setShow(true);

    // const handleDeleteUser = () => {
    //     deleteUser()
    // }

    // handle Update user info
    const [updateModalShow, setUpdateModalShow] = useState(false)


    return (
        <Container className="user-details">
            { user &&
            <div className="user-details-container" >
                <div className="name-container">
                    <h3><strong>Hello, {name}</strong></h3>
                </div>
                <Row>
                <Col className="col-12 col-lg-6 py-3">
                    <div className="info-piece-container shadow-light-out">
                        <div className="text-end">
                            <small>
                                <button className="link-btn" onClick={() => setUpdateModalShow(true)}>Edit personal info</button>
                            </small>
                            <UpdateUserInfoForm show={updateModalShow} onHide={() => setUpdateModalShow(false)}/>
                        </div>
                        <Row>
                            <h5><strong>{name}</strong> </h5>
                        </Row>
                        <Row>
                            <p>{phoneIcon} <strong>{phoneNo}</strong> </p>
                        </Row>
                        <Row>
                            <p>{mailIcon} <strong>{email}</strong></p>
                        </Row>
                        <br/>
                        <Row>
                            <h5>Address</h5>
                            <p>City: <strong>{city} </strong></p>
                            <p>Address: <strong>{address} </strong></p>
                            <p>Building: <strong>{building} </strong></p>
                            <p>Floor: <strong>{floorNo} </strong></p>
                            <p>Apartment: <strong>{apt} </strong></p>
                            <p>NearbyLandmark: <strong>{landmark} </strong></p>
                            <p>Work Address?  {workAddress ? <strong>Yes</strong> : <strong>No</strong>} </p>
                        </Row>
                    </div>
                </Col>
                <br/>
                <Col className="col-12 col-lg-6 py-3">
                    <div className="info-piece-container no-padding trans">
                        <h5>My Orders</h5>
                    </div>
                    <div className="info-piece-container no-padding trans">
                        {orders && orders.length
                        ? <div>{orders.map(order => (
                            <div  className="info-piece-container shadow-light-out">
                                <p>OrderID: #{order.orderNo}</p>
                                <p>Date: {order.orderDate}</p>
                                <p>Time: {order.orderTime}</p>
                                <p>Total: <strong>EGP {order.grandTotal}</strong></p>
                                <p>Payment method: {order.paymentMethod}</p>
                                <p>Status: <span className={order.status === 'delivered' ? "successful" : order.status === 'in progress' ? "attend" : order.status === 'canceled' ? "error" : ""}>{order.status}</span></p>
                            </div>
                        ))} </div>
                        : <p className="grey">You haven't ordered yet.</p>
                        }
                    </div>
                    <br/>
                </Col>
                </Row>
                <Row>
                    <div className="info-piece-container trans ">
                        <button className="link-btn"
                            onClick={handleLogout}>
                            <small>{logoutIcon} LOG OUT</small>
                        </button>
                        <br/>
                        {/* <button className="link-btn-del"
                            onClick={handleModalShow}>
                            <small>{deleteIcon} DELETE ACCOUNT</small>
                        </button> */}

                        {/* <Modal show={show} onHide={handleModalClose} className="modal-container">
                            <Modal.Header closeButton>
                            <Modal.Title><strong>DELETE ACCOUNT</strong></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are you sure you want to delete your account?
                            </Modal.Body>
                            <Modal.Footer>
                            <Button id="btn-sec-top-left" onClick={handleModalClose}>
                                Cancel
                            </Button>
                            <Button id="btn-warn-top-left" onClick={handleDeleteUser}>
                                Delete Account
                            </Button>
                            </Modal.Footer>
                        </Modal> */}
                    </div>
                </Row>
            </div>
            }
        </Container>
    )
}

export default UserDetails;