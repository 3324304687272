import React from "react";

import { useNavigate } from "react-router-dom";
import { useState,useEffect } from "react"
// bs5 imports
import Container from 'react-bootstrap/Container';
import { Row, Col, Stack, Button } from 'react-bootstrap'
import { NEW_BASE_URL } from "../constants/serv"

// components
import Hero from "../components/Hero";
import TitleHeader from "../components/TitleHeader";
import NewCategoryCard from "../components/NewCategoryCard";
import CategoryCard from "../components/TypeCard";
import RequestNewProductCard from "../components/RequestNew";
import CenterMode from "../components/TestimonialSlider";
import ProductsSlider from "../components/ProductsSlider";
import BestSellerSlider from "../components/BestSellerSlider";
import FeedbackSlider from "../components/FeedbackSlider";

// img imports
import shop4men from '../assets/images/Men.jpg'
import shop4women from '../assets/images/Women.jpg'
import shopSneakers from '../assets/images/sneakers-img2.jpg'
import shopCreaseProt from '../assets/images/crease-protectors-01.jpg'
import adidasLogo from '../assets/logos/adidas-logo.png'
import jordanLogo from '../assets/logos/jordan-logo.png'
import nikeLogo from '../assets/logos/nike-logo.png'
import newBalanceLogo from '../assets/logos/new-balance.png'
import aboutBriefImage from '../assets/images/our-story-homepage-2.jpg'
import aboutBriefImage2 from '../assets/images/our-story-homepage1.jpg'

import {truckIcon, medalIcon, locationIcon, socksIcon} from '../constants/grabIcons'


function Homepage() {

    const navigate = useNavigate()
    const [triggerToggle, setTriggerToggle] = useState(true)
    const [categories, setCategories] = useState()

    useEffect(() => {
        const fetchCategories = async () => {
            const res = await fetch(`${NEW_BASE_URL}/api/categories`)
            //console.log(res)
            var json = await res.json()
            json=json.data
            //console.log(json)

            if (res.ok) {
                setCategories(json)
            } else {
                console.log('error occurred', res)
            }
        }

        fetchCategories()
    }, [triggerToggle])

    return (
        <Container className="homepage-styles">
            <Row>
                <Col>
                    <Hero />
                </Col>
            </Row>
            <Row>
                <div className="divider-general">
                    <hr className="divider-line" />
                </div>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <TitleHeader title='BEST SELLER' titleFaded='Products' />
                    </Row>
                    <Row>
                        <div><BestSellerSlider props={{filter: "newArrivals"}}/></div>
                    </Row>
                </Col>
            </Row>
            <Row>
                <div className="divider-general">
                    <hr className="divider-line" />
                </div>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <TitleHeader title='NEW ARRIVALS' titleFaded='FEATURED' />
                    </Row>
                    <Row>
                        <div><ProductsSlider props={{filter: "newArrivals"}}/></div>
                    </Row>
                </Col>
            </Row>
            
            <Row>
                <div className="divider-general">
                    <hr className="divider-line" />
                </div>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <TitleHeader title='SHOP GENDER' titleFaded='PRODUCTS' />
                    </Row>
                    <Row>
                        <Col className="col-12 col-md-2"></Col>
                        <Col className="col-12 col-md-4">
                            <CategoryCard cardImage={shop4men} cardTitle={"MEN"} />
                        </Col>
                        <Col className="col-12 col-md-4">
                            <CategoryCard cardImage={shop4women} cardTitle={"WOMEN"} />
                        </Col>
                        <Col className="col-12 col-md-2"></Col>
                    </Row>
                </Col>
            </Row>
       
        
            {/* <Row>
                <Col>
                    <Row>
                        <TitleHeader title='SHOP CATEGORY' titleFaded='PRODUCTS' />
                    </Row>
                    <Row>
                    
                    {categories && categories.map((p, i) => (
                                        
                                        <Col Col className="col-12 col-md-6">
                                            <Row>
                                            <Col Col className="col-1 col-md-2"></Col>   
                                            <Col Col className="col-12 col-md-10 ">
                                            <NewCategoryCard cardImage={p.image} cardTitle={p.name} cardId={p.id} cardType={"category"} />
                                            </Col>
                                            </Row>
                                        </Col>
                                        ))}
                        
                    </Row>
                </Col>
            </Row> */}
            <Row>
                <div className="divider-general">
                    <hr className="divider-line" />
                </div>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <TitleHeader title='SHOP BRANDS' titleFaded='PRODUCTS' />
                    </Row>
                    <Row>
                        <Col className="col-1 col-md-4"></Col>
                        <Col className="col-10 col-md-4 center">
                            <Stack direction="horizontal" gap={2}>
                               
                                <Button variant="light" style={{marginLeft:`12%`}}
                                    onClick={() => navigate(`/products/:jordan`, { state: { filterValue: 'Jordan' } })}>
                                    <img alt="jordan"  src={jordanLogo} width={45}/>
                                </Button>
                                <Button variant="light"
                                    onClick={() => navigate(`/products/:adidas`, { state: { filterValue: 'Adidas' } })}>
                                    <img alt="adidas" src={adidasLogo} width={50}/>
                                </Button>
                                <Button variant="light"
                                    onClick={() => navigate(`/products/:new-balance`, { state: { filterValue: 'New Balance' } })}>
                                    <img alt="new balance" src={newBalanceLogo} width={50} />
                                </Button>
                                <Button variant="light"
                                    onClick={() => navigate(`/products/:nike`, { state: { filterValue: 'Nike' } })}>
                                    <img alt="nike" src={nikeLogo} width={50}/>
                                </Button>
                            </Stack>
                        </Col>
                        <Col className="col-1 col-md-4"></Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <div className="divider-general">
                    <hr className="divider-line" />
                </div>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col className="col-12 "><RequestNewProductCard /></Col>
                        <Col className="col-12" ></Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <div className="divider-general">
                    <hr className="divider-line" />
                </div>
            </Row>
            <Row>
                <Col>
                    <Row><TitleHeader title='WHAT THEY SAY ABOUT US' titleFaded='REVIEWS' /></Row>
                    <Row><CenterMode /></Row>
                </Col>
            </Row>
            <Row>
                <div className="divider-general">
                    <hr className="divider-line" />
                </div>
            </Row>
            <Row>
                <Col>
                    <Row><TitleHeader title='HAPPY CLIENTS!' titleFaded='FEEDBACK' /></Row>
                    <Row><FeedbackSlider /></Row>
                </Col>
            </Row>
            <Row>
                <div className="divider-general">
                    <hr className="divider-line" />
                </div>
            </Row>
            <Row>
                <Col>
                    <Row><TitleHeader title='OUR STORY' titleFaded='ABOUT US' /></Row>
                    <Row className="about-brief">
                        <Col className="col-12 col-lg-6">
                            <Row>
                                <p>Our mission is to help everyone who
                                    loves to look unique by providing sneakers, of high quality, with their
                                    own packaging, exactly like the original, and delivery on time.</p>
                            </Row>
                            <Row>
                                <Col className="key-features col-6">
                                    <h3>{medalIcon}</h3>
                                    <small><strong>First Mirror</strong></small>
                                    <br />
                                    <small> Mirror original premium quality.</small>
                                </Col>
                                <Col className="key-features col-6">
                                    <h3>{truckIcon}</h3>
                                    <small><strong>In 5 days</strong></small>
                                    <br />
                                    <small> Fast, safe & guaranteed, we deliver within 5 days</small>
                                </Col>
                                <Col className="key-features col-6">
                                    <h3>{locationIcon}</h3>
                                    <small><strong>All over Egypt</strong></small>
                                    <br />
                                    <small> We deliver to all parts of Egypt except Sinai</small>
                                </Col>
                                <Col className="key-features col-6">
                                    <h3>{socksIcon}</h3>
                                    <small><strong>Comfort</strong></small>
                                    <br />
                                    <small>Comfort in every walk, for every day.</small>
                                </Col>
                            </Row>
                            <br/>
                        </Col>
                        <Col className="col-6 col-lg-3" >
                            <img alt="two-sneakers" src={aboutBriefImage} />
                        </Col>
                        <Col className="col-6 col-lg-3" >
                            <img alt="two-sneakers" src={aboutBriefImage2} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <div className="divider-general">
                    <hr className="divider-line" />
                </div>
            </Row>
        </Container>
    );
}

export default Homepage;