import { useNavigate } from "react-router-dom";
import { useAuthContext } from "./useAuthContext"
import { NEW_BASE_URL } from "../constants/serv";

export const useLogout = () => {

    const { dispatch } = useAuthContext()
    const navigate = useNavigate()

    const logout = async() => {
        const res = await fetch(`${NEW_BASE_URL}/api/logout`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json','Authorization':'Bearer '+localStorage.getItem('_token')}
        })
        localStorage.removeItem('user')
        localStorage.removeItem('_token')
        dispatch({type: 'LOGOUT'})
        navigate('/login')
    }

    return { logout }
}