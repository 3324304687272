import React from "react";
import { useEffect, useState } from "react"
import Slider from "react-slick";
import ReactPlayer from 'react-player'
import { Col } from "react-bootstrap";

import { FEEDBACK } from '../constants/feedbackCollection'
import FeedbackCard from "./FeedbackCard";
import axios from "axios"
import { NEW_BASE_URL } from "../constants/serv"

function FeedbackSlider() {

  const [triggerToggle, setTriggerToggle] = useState(true)

    const [products, setProducts] = useState([])
    useEffect(() => {
        const fetchProducts = async () => {
            const res = await fetch(`${NEW_BASE_URL}/api/reviews`)
            console.log(res)
            var json = await res.json()
            json=json.data
            //console.log(json)

            if (res.ok) {
                setProducts(json)
            } else {
                console.log('error occurred', res)
            }
        }

        fetchProducts()
    }, [triggerToggle])


    console.log('clients',products);

  const settings = {
    className: "center",
      focusOnSelect: true,
      centerMode: true,
      infinite: true,
      centerPadding: "10px",
      slidesToShow: 3,
      speed: 500,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };
  return (
    <div className="testimonials">
        <Slider {...settings}>
        { products.map(f => (
             
             <div className="review-wrapper">
                  <ReactPlayer url={f.video} className="video-preview" controls={true} width="100%" height="80%" />
              </div>
            ))}
       
        </Slider>
      </div>

  );
}

export default FeedbackSlider;
