import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { NEW_BASE_URL } from "../constants/serv";

// bs5 imports
import { Container, Row, Col } from "react-bootstrap";

// components import
import ControlledCarousel from "../components/Carousel";
import ProductInfo from "../components/ProductInfo";
import RequestNewProductCard from "../components/RequestNew";
import ProductsSlider from "../components/ProductsSlider";
import PreviewSection from "../components/PreviewSection";
import FAQs from "../components/FAQs";

const ProductPage = () => {

    // getting the id of selected product
    const location = useLocation()
    let productId = location.state.productId

    const [product, setProduct] = useState(null)
    const [productGender, setProductGender] = useState(null)

    useEffect(() => {
        const fetchProduct = async () => {
            const response = await fetch(`${NEW_BASE_URL}/api/products/${productId}`)
            var json = await response.json()
            json = json.data;
            if (response.ok) {
                setProduct(json)
                setProductGender(json.gender)
            }
        }

        fetchProduct()

    }, [productId])


    return (
        <Container>
            {product &&
                <div>
                    <Row>
                        <Col className="col-12 col-md-6">
                            <ControlledCarousel props={product} />
                        </Col>
                        <Col className="col-12 col-md-6">
                            <ProductInfo props={product} />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        {product.material || product.color || product.gender ?
                            <div>
                                <Row>
                                    <strong className="descrip">DESCRIPTION</strong>
                                </Row>
                                <Row>
                                    <div>
                                        
                                        {product.gender ? <div><small>Gender: <strong>{product.gender}</strong></small><br /></div> : <></>}
                                    </div>
                                </Row>
                            </div>
                            : <></>
                        }
                    </Row>
                    <Row>
                        <div className="divider-general">
                            <hr className="divider-line" />
                        </div>
                    </Row>
                    <Row>
                        <PreviewSection props={product} />
                    </Row>

                </div>
            }
            <Row>
                <div className="divider-general">
                    <hr className="divider-line" />
                </div>
            </Row>
            <Row>
                <RequestNewProductCard />
            </Row>
            <Row>
                <div className="divider-general">
                    <hr className="divider-line"/>
                </div>
            </Row>
            <Row><h4>Products you may also like</h4></Row>
            <Row>
                { productGender &&
                    <ProductsSlider props={{filter: productGender}} />
                }
            </Row>
            <Row>
                <div className="divider-general">
                    <hr className="divider-line" />
                </div>
            </Row>
            <Row>
                <FAQs />
            </Row>
            <br/>
        </Container>
    )
}

export default ProductPage;