import { Accordion, Container } from "react-bootstrap"
import { useLocation } from "react-router-dom"

const PoliciesAccordion = () => {

    const location = useLocation()
    let key
    if(location.state != null) {
        key = location.state.filterKey
    } else {
        key = '0'
    }

    return (
        <Container className="py-3 full-page">
            <Accordion defaultActiveKey={key}>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h4>Shipping Policy</h4></Accordion.Header>
                    <Accordion.Body>
                        <ul className="circ-list-style">
                            <li>We deliver within 5 working days</li>
                            <li>We deliver to all parts of the Arab Republic of Egypt except Sinai and some areas in the Red Sea.</li>
                            <li>If the order is confirmed and shipped, it cannot be shipped Amending or canceling it.</li>
                            <li>The representative contacts the customer 3 times a day 3 different days in case of no response the order is cancelled.</li>
                            <li>To achieve customer satisfaction, there must be flexibility At the time of receipt of the application, a maximum of 4 days From the date of confirmation of the order, and if the period expires, the order will be cancelled.</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h4>Return & Exchange Policy</h4></Accordion.Header>
                    <Accordion.Body>
                        <ul className="circ-list-style">
                            <li>Customer satisfaction is the number one priority for us, so if you are not satisfied with your order, you can exchange or return within 48 hours of receipt.</li>
                            <li>If the product did not meet your expectations and did not suit you at the time of product pick-up, it will be returned and delivery fees will be applied.</li>
                        </ul>
                        <>
                            <strong>Conditions for return: </strong>
                            <p>The returned product is your responsibility until it reaches us, So we have the right to refuse exchange or return in case: </p>
                            <ol>
                                <li>The product has been worn</li>
                                <li>It was not in its original state in which you received it shoes should be returned in (within the original shoe box).</li>
                            </ol>
                        </>
                        <br />
                        <h6><strong>Case 1:</strong></h6>
                        <p> The customer did not receive the order after inspecting it for any of the following reasons:</p>
                        <ol>
                            <li>The size is not required by us</li>
                            <li>Damage to the piece itself</li>
                        </ol>
                        <p><strong>Policy: </strong>Nothing was paid and we have been notified and we are trying to address the reason </p>
                        <br />
                        <h6><strong>Case 2:</strong></h6>
                        <p>If it is for the following reasons after inspection as well</p>
                        <ol>
                            <li>The size requested by them needs to be smaller or larger</li>
                            <li>The order was not to their liking</li>
                        </ol>
                        <p><strong>Policy: </strong>Shipping is paid only, and the entire shipment is delivered to scars from boxes and shoes, even if there are gifts in their case, and we try to address the reason.</p>
                        <br />
                        <h6><strong>Case 3:</strong></h6>
                        <p>If it was because they did not apologize and evaded the representative without informing us of that after the order was shipped.</p>
                        <p><strong>Policy: </strong>It will be placed on the blacklist and no order will be received from it again</p>

                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container>
    )
}

export default PoliciesAccordion