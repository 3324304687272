import { useEffect, useState } from "react"
import { Row, Col, Button, ButtonGroup, Dropdown, DropdownButton, Stack, Modal } from "react-bootstrap"
import { BASE_URL , NEW_BASE_URL} from "../constants/serv"

// icons
import { deleteIcon } from "../constants/grabIcons"


const Orders = () => {

    const [loading, setLoading] = useState(false)
    const [triggerToggle, setTriggerToggle] = useState(true)

    const [allOrders, setAllOrders] = useState()

    useEffect(() => {
        const fetchOrders = async () => {
            const res = await fetch(`${NEW_BASE_URL}/api/orders/all`)
            const json = await res.json()

            if (res.ok) {
                setAllOrders(json.data)
            }
        }

        fetchOrders()
    }, [triggerToggle])

    const [delModalShow, setDelModelShow] = useState(false) //delete order modal
    const [orderToDel, setOrderToDel] = useState()

    const openModalDel = (order) => {
        setOrderToDel(order)
        setDelModelShow(true)
    }

    const handleDelete = (order) => {
        setLoading(true)
        const id = order._id
        const deleteOrder = async () => {
            const response = await fetch(`${NEW_BASE_URL}/api/orders/${id}`, {
                method: 'DELETE'
            })
            await response.json()

            if (response.ok) {
                setDelModelShow(false)
                setLoading(false)
                setTriggerToggle(!triggerToggle)
            }
        }

        deleteOrder()
    }

    const handleChangeStatus = (id, newStatus) => {
        const changeStatus = async () => {
            const res = await fetch(`${NEW_BASE_URL}/api/orders/${id}`, {
                method: 'POST',
                body: JSON.stringify({ newStatus }),
                headers: { 'Content-Type': 'application/json' }
            })
            if (res.ok) {
                setTriggerToggle(!triggerToggle)
            }
        }

        changeStatus()

    }

    return (
        <div>
            <div className='plain-wrapper'>
                <div className='plain-wrapper'>
                    <h4><strong>Orders Managment</strong></h4>
                    <small className='grey'>All Orders ( {allOrders && allOrders.length} )</small>
                </div>
                {allOrders &&
                    allOrders.map((order) => (
                        <div key={order._id}>
                            <div className='border-wrapper'>
                                <div className='plain-wrapper text-end'>
                                    <ButtonGroup size="sm">
                                        <DropdownButton as={ButtonGroup} title="Order Status" id="bg-nested-dropdown" size='sm' variant='outline-secondary'>
                                            <Dropdown.Item onClick={() => handleChangeStatus(order._id, 'delivered')}>
                                                Delivered
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleChangeStatus(order._id, 'canceled')}>
                                                Canceled
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleChangeStatus(order._id, 'in progress')}>
                                                In Progress
                                            </Dropdown.Item>
                                        </DropdownButton>
                                        <Button variant='outline-danger' disabled={loading}
                                            onClick={() => openModalDel(order)}>
                                            {deleteIcon}
                                        </Button>
                                    </ButtonGroup>
                                    <Modal show={delModalShow} onHide={() => setDelModelShow(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Delete Order</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <p>Are you sure you want to delete this order?</p>
                                            <p>Order about to be deleted:
                                                {orderToDel
                                                    ? <strong className="red">#{orderToDel.orderNo}</strong> //- <strong>{orderToDel.gender}</strong>
                                                    : <>cannot find order</>}
                                            </p>
                                            <br />
                                            <small className="grey">Note: Deleting this order will delete it from both: your dashboard and the user info page. <br /> This means that by deleting this order, it will no longer be found and CANNOT be retrieved!</small>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={() => setDelModelShow(false)}>
                                                Close
                                            </Button>
                                            <Button variant="danger" onClick={() => handleDelete(orderToDel)}>
                                                Delete Order
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>

                                <Row className={order.status === 'in progress' ? "bg-attend" : order.status === 'delivered' ? "bg-green" : "bg-red"}>
                                    <Col className='col-12 col-md-3'>
                                        <small>OrderID: #<strong>{order.orderNo}</strong></small>
                                    </Col>
                                    <Col className='col-12 col-md-3'>
                                        <small className="px-2">Status: <strong >{order.status}</strong></small>
                                    </Col>
                                    <Col className='col-12 col-md-3'>
                                        <small>Date: <strong>{order.orderDate}</strong></small>
                                    </Col>
                                    <Col className='col-12 col-md-3'>
                                        <small>Time: <strong>{order.orderTime}</strong></small>
                                    </Col>
                                </Row>
                                <br />
                                <Row className='grey'>
                                    <Col className='col-12 col-md-6'>
                                        <small><strong className="pri-txt">Purchased Products <span className="light-bg black">( {order.purchasedProducts.length} )</span></strong></small>
                                        {order.purchasedProducts.map(product => (
                                            <ul className='list p-0' key={`${product.id}${product.sizeChosen}`}>
                                                <li><small>Product: <strong>{product.name || product.item.name}</strong></small></li>
                                                <li>
                                                    <Stack direction="horizontal" gap={5}>
                                                        <small>Size: <strong>{product.sizeChosen}</strong></small>
                                                        <small>qty: <strong>{product.count}</strong></small>
                                                    </Stack>
                                                </li>
                                            </ul>
                                        ))
                                        }
                                        <ul className="list">
                                            <li>
                                                <small><strong className="pri-txt">Total (EGP)</strong></small>
                                                <small className='grey'>
                                                    <ul className="list">
                                                        <li>Estimated: <strong>{order.total.subtotal}</strong></li>
                                                        <li>Discount: <strong>{order.total.discountValue}</strong></li>
                                                        <li>Promocode: <strong>{order.total.discountPromo}</strong></li>
                                                        <li>Shipping: <strong>{order.total.shipping}</strong></li>
                                                    </ul>
                                                </small>
                                            </li>
                                        </ul>
                                        <small>Grand Total: <strong className="black">EGP {order.grandTotal}</strong></small>
                                    </Col>
                                    <Col className='col-12 col-md-6 border-left'>
                                        <small><strong className="pri-txt">User Info</strong></small>
                                        <ul className='list p-0'>
                                            <li><small>Name:<strong> {order.orderedBy.userName}</strong></small></li>
                                            <li><small>PhoneNo: <strong> {order.orderedBy.userPhone}</strong></small></li>
                                            <li><small>Email: <strong> {order.orderedBy.userEmail}</strong></small></li>
                                            <li><small>City: <strong> {order.orderedBy.city}</strong></small></li>
                                            <li><small>Address: <strong> {order.orderedBy.address}</strong></small></li>
                                            <li><small>building: <strong> {order.orderedBy.building}</strong></small></li>
                                            <li><small>apt: <strong> {order.orderedBy.apt}</strong></small></li>
                                            <li><small>Landmark: <strong> {order.orderedBy.landmark}</strong></small></li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))}
            </div>
            <br />
            <Row>
                <div className='center hidden'>
                    <Button variant='outline-secondary' disabled>Load More</Button>
                </div>
            </Row>
        </div>
    )
}

export default Orders