import React from "react";
import { useEffect, useState } from "react"
import Slider from "react-slick";
import { Col } from "react-bootstrap";

import { FEEDBACK } from '../constants/feedbackCollection'
import FeedbackCard from "./FeedbackCard";
import axios from "axios"
import { NEW_BASE_URL } from "../constants/serv"

function FeedbackSlider() {

  const [triggerToggle, setTriggerToggle] = useState(true)

    const [products, setProducts] = useState([])
    useEffect(() => {
        const fetchProducts = async () => {
            const res = await fetch(`${NEW_BASE_URL}/api/happy-clients`)
            console.log(res)
            var json = await res.json()
            json=json.data
            //console.log(json)

            if (res.ok) {
                setProducts(json)
            } else {
                console.log('error occurred', res)
            }
        }

        fetchProducts()
    }, [triggerToggle])


    console.log('clients',products);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
            { products.map(f => (
                <Col key={f.id} className="card-wrapper">
                    <FeedbackCard name={f.name} content={f.message} lang="ar" />
                </Col>
            ))}
      </Slider>
    </div>
  );
}

export default FeedbackSlider;
