import React from "react";
import { useState } from "react";
import { Container, Button } from "react-bootstrap";

import RequestItem from "./RequestForm";

import ShoeIllustration from '../assets/images/shoes-illustration.png'

const RequestNewProductCard = () => {

    const [show, setShow] = useState(false);

    return (
        <Container className="req-card-container">
            <div className="card-container">
                <div>
                   
                    <div className="text-sec shadow-light-out">
                        <div className="dashed-border">
                            <h4> <strong>Looking for something specific?</strong></h4>
                            <h6>A size? A color? Maybe a model?</h6>
                            <h6>Tell us what you're looking for and we'll provide it if possible!</h6>
                            <Button id="btn-sec-top-left" onClick={() => setShow(true)}>
                                RECOMMEND NOW
                            </Button>
                            <RequestItem
                                show={show}
                                onHide={() => setShow(false)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default RequestNewProductCard