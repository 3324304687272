import React, { useEffect, useState } from "react";

import Slider from "react-slick";
import ProductCard from "./ProductCard";

import { NEW_BASE_URL } from "../constants/serv";
import LoadingComponent from "./LoadingComponent";

const BestSellerSlider = ({props}) => {

  const {filter} = props
  //console.log(filter)

  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await fetch(`${NEW_BASE_URL}/api/best-seller`)
      let json = await response.json()
      json = json.data
      if (response.ok) {
        setProducts(json)
        setLoading(false)
      }
    }

    fetchProducts()
  }, [])

  var settings = {
    dots: true,
    infinite: true,
    speed: 750,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  }

  let filteredSlider

  if(filter === 'newArrivals') {
    filteredSlider = products.filter(p => p.featured)
  } else if((filter === "men" || "women") && filter !== 'unisex') {
    filteredSlider = products.filter(p => p.gender === filter || p.gender === 'unisex')
  } else {
    filteredSlider = products
  }


  return (
    <div className="slider-container">

      { !loading ?
        <Slider {...settings}>
          {products && filteredSlider &&
            filteredSlider.filter(p => p.availability).map((product) => (
              <div key={product._id} className="card-wrapper">
                <ProductCard data={product} />
              </div>
            ))
          }
        </Slider>
        :
        <LoadingComponent />
      }
    </div>
  )
}

export default BestSellerSlider;