import React from 'react';
import { Button, Modal, Table, Tab, Tabs } from 'react-bootstrap';

import measureSize from '../assets/images/Foot-lenght.webp'

function SizeChart(props) {
    return (
        <Modal
            {...props}
            size="md" aria-labelledby="contained-modal-title-vcenter"
            className='sizechart-modal'
        >
            <Modal.Header closeButton >
                <Modal.Title id="contained-modal-title-vcenter">
                    Size Chart
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='sizechart-body'>
                <Tabs
                    defaultActiveKey="men" transition={false}
                    className="all-tabs " fill>
                    <Tab eventKey="men" title="MEN" id='tab'>
                        <Table className='table small'>
                            <thead>
                                <tr>
                                    <th>US</th>
                                    <th>EU</th>
                                    <th>UK</th>
                                    <th>Foot Length</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>8</td>
                                    <td>41</td>
                                    <td>7</td>
                                    <td>26 cm</td>
                                </tr>
                                <tr>
                                    <td>8.5</td>
                                    <td>42</td>
                                    <td>7.5</td>
                                    <td>26.5 cm</td>
                                </tr>
                                <tr>
                                    <td>9.5</td>
                                    <td>43</td>
                                    <td>8.5</td>
                                    <td>27.5 cm</td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>44</td>
                                    <td>9</td>
                                    <td>28 cm</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>45</td>
                                    <td>10</td>
                                    <td>29 cm</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab eventKey="women" title="WOMEN">
                        <Table responsive className='sizechart-table'>
                            <thead>
                                <tr>
                                    <th>US</th>
                                    <th>EU</th>
                                    <th>UK</th>
                                    <th>Foot length</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>5.5</td>
                                    <td>36</td>
                                    <td>3.5</td>
                                    <td>23 cm</td>
                                </tr>
                                <tr>
                                    <td>6.5</td>
                                    <td>37.5</td>
                                    <td>4.5</td>
                                    <td>23.5 cm</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>38</td>
                                    <td>5</td>
                                    <td>24 cm</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>39</td>
                                    <td>6</td>
                                    <td>24.5 cm</td>
                                </tr>
                                <tr>
                                    <td>8.5</td>
                                    <td>40</td>
                                    <td>6.5</td>
                                    <td>25 cm</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Tab>
                </Tabs>
                <div className='foot-length'>
                    <img alt='how to measure size' src={measureSize} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button id='btn-sec-top-left' onClick={props.onHide}>CLOSE</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SizeChart;



