import axios from "axios"
import { useState } from "react"
import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap"
import { NEW_BASE_URL } from "../constants/serv"
import { useSKUGenerator } from "../customHooks/useSKUGenerator"
import { useNavigate } from "react-router-dom"

const AddNewCategoryForm = () => {

   
    const navigate = useNavigate()
    // UI feedback
    const [loading, setLoading] = useState(false)
    const [isApproved, setIsApproved] = useState(false)

    const [avail, setAvail] = useState(true)
    const [feat, setFeat] = useState(false)
    //const [productImagesArr, setProductImagesArr] = useState([{}])


    const [name, setName] = useState("")
    const [gender, setGender] = useState("")
    const [is_one_size, setSize] = useState(0)
    const [productImages, setProductImages] = useState([])
  


    const handleSaveProduct = (e) => {
        e.preventDefault()
        setLoading(true)

        // preparing the product object to send to db
       
        if (gender.length === 0) {
            setGender("Genderless")
        }
        if (name.length === 0) {
            setName("NoName")
        }

        // generate SKU
        const data = { name, gender,is_one_size }

        const saveProduct = () => {

            const formData = new FormData();
            formData.append('name', name)
            formData.append('gender', gender)
            formData.append('is_one_size', is_one_size)
        
            // Append Product Images
            if(productImages.length > 0) {
              
                for (let i = 0; i < productImages.length; i++) {
                  //  console.log(productImages[i])
                    formData.append('productImages[]', productImages[i])
                }
            } else formData.append('productImages', productImages)

            // Append Preview Images
            
            // }
            // post to db
            axios.post(`${NEW_BASE_URL}/api/categories`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(res => {
                console.log('res', res)
                setLoading(false)
                /*
                if (res.status === 200) {
                    setTriggerLoad(!triggerLoad)
                }
                */
                navigate('/admin/homepage')
            }).catch(err => console.log('err', err))


        }

        saveProduct()
    }

    return (
        <Container className="py-5">
            <Row>
                <Col className='col-12'>
                    <h4><strong>Add New Category</strong></h4>
                </Col>
            </Row>
            <Row>
                <Form encType="multipart/form-data">
                    <Row className="py-1">
                        <strong>Basic Info</strong>
                        <small className="grey tiny-txt">Please fill in all fields</small>
                    </Row>
                    <Row>
                        <Col className="col-12 col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Control type="text" name="name" placeholder="Category Name"
                                    onChange={e => setName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                
         
                        <Col className="col-6 col-lg-3">
                            <Form.Group className="mb-3">
                                <Form.Select aria-label="Default select example" name="gender"
                                    onChange={e => setGender(e.target.value)}
                                >
                                    <option>-- gender</option>
                                    <option value="men">men</option>
                                    <option value="women">women</option>
                                    <option value="unisex">unisex</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col className="col-6 col-lg-3">
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    id="default-checkbox" name="is_one_size"
                                    label="Is one size" defaultValue={is_one_size}
                                    onChange={e => {
                                        setSize(is_one_size)
                                      
                                    }}
                                />
                            </Form.Group>
                        </Col>
               
                    </Row>
      
                    <br />
    
                  
                    <Row>
                        <strong>Images and Media</strong>
                        <small className="grey tiny-txt">All images must be: .jpg or .png, Dimension are preffered in square, min: (450x450px), max: (700x700px).</small>
                    </Row>
                    <br />
                    <Row>
                        <Col className="col-12 col-lg-4">
                            <>
                                <strong className="grey">Category Images</strong>
                                <Form.Group className="mb-3" >
                                    <Form.Control type="file" multiple name="productImages"
                                        onChange={(e) => {
                                            setProductImages(e.target.files)
                                            console.log(productImages, e.target.files)
                                        }}
                                    />
                                    <small className="grey tiny-txt">6 images maximum</small>

                                </Form.Group>
                            </>
                        </Col>
                        
                    
                        
                    </Row>
                    <div>

                    </div>
                    <div>


                    </div>
                    <Row>
                        <div className="text-end">
                            <Button variant="success" //disabled={loading}
                                onClick={e => handleSaveProduct(e)}>
                                Save 
                            </Button>
                        </div>
                    </Row>
                </Form>
            </Row>
        </Container>
    )
}

export default AddNewCategoryForm