import React from "react";


const FeedbackCard = ({name, content, lang}) => {

    return (
        <div className="feedback-card">
            <div className="feedback-wrapper shadow-light-out">
                <p className="q-marks-large">"</p>
                <p className={lang==='ar'? "content ar" : "content en"}>
                    {content}
                </p>
            </div>
            <div className="name-wrapper shadow-light-out">
                <strong>{name}</strong>
            </div>
        </div>
    )
}

export default FeedbackCard