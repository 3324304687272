
export const FEEDBACK = [
    {
        id: 0 , name: "Abode", lang: 'ar',
        content: "لا بصراحة انا كنت شايل هم يجي مقطوع من حتة او الجودة بتاعت الشوز مش كويسة بس للأمانة جداً ممتازة و زي من الاصلي وانا باذن الله تعاملي حيكون معاكم فقط 🖤🖤",
    },
    {
        id: 1 , name: "Sameh Ahmed", lang: 'ar',
        content: "بجدد شكرا جدا على كل حاجة بجد الحمدلله كله مظبوط و الخامة تحفة و كمان الهدايا الي في البوكس و كل حاجة بجد نضيفة و جميلة جدا و شكرا جدا اني احتاجت ان الاوردر يوصل في معاد قبل ميعاده و ده حصل بجد يعني و الطريقة و التعامل جميلة فا شكرا جدا بجد"
    },
    {
        id: 2 , name: "Karma Remon", lang: 'en',
        content: "Elshoes Isa wadla w bgd tuhfaa Elfinishing w elpackaging w klo tuhfaa thank u w isa msh a5er mra"
    },
    {
        id: 3 , name: "Mohamed Rafat", lang: 'ar',
        content: "بجد انتو اكتشاف كنت بدور عليها بقالي كتير في مصر ومكنتش بلقيها بجد شكرا جدا وفعلا مغيره شكل الجذمه كمان وبتحافظ عليها غير شركه الشحن الي معاكم طبعا ناس محترمه شكرا جدا"
    },
    {
        id: 4 , name: "Basel Omar", lang: 'ar',
        content: "البروتيكترز شغالة زي الفل و الله و احلي حاجة السعر بتاعكو لان انا بقالي سنتين عايز اجيبهم بس كانو علي امازون ب ٤٠٠ جنيه و هما اصلا بيتباعو برا ب ٥ دولار و ٧ دولار فشكرا جدا و الله و ان شاء الله هبقا اشتري تاني"
    },
    {
        id: 5 , name: "Mohamed Essmaiel", lang: 'ar',
        content: "ميرسي ليكو بجد حاجه وصلت خامة ممتازة جدا و سعرها ممتاز جدا مقارنة بباقي الستورز"
    },
    {
        id: 6 , name: "Victoria", lang: 'en',
        content: "thank you so much the quality is soooooooo good and the shoe look and feel amazing begad thank you so so much"
    },
    {
        id: 7 , name: "Ahmed", lang: 'ar',
        content: "الاوردر وصل الحمدلله و الحاجات و خامتها تحفة و مش اخر تعامل إن شاء الله"
    },
    {
        id: 8 , name: "Lilwinto", lang: 'ar',
        content: "المنتج والله زي الفل مفيهوش عيب و التقفيل جامد اوي و الخدمة حضراتكم محترمين جدا مشاء الله و وصل في الوقت الي هما قالولي عليه بعد ما طلبت"
    },
    {
        id: 9 , name: "Adel", lang: 'ar',
        content: "اه النهاردة استلمتوا و الاوردر جامد و الله و الكواليتي و المقاس و كل حاجة مظبوطة ولا غلطة ما شاء الله"
    },
    {
        id: 10 , name: "Amr", lang: 'ar',
        content: "الاوردر وصل و الكواليتي و الباكدجينج في قمة النضافة و الفخامة و حاجة تمنها فيها كدهو شكرا بجد على التحفة دي و مش هيبقى اخر تعامل ان شائ الله"
    },
    {
        id: 11 , name: "Khaled Saied", lang: 'ar',
        content: "الشوز لسه واصل حالا و الكواليتي جامده  فشخ و شكرا على الشراب و اللبيسة و بجد من احسن البيدجات اللي اتعاملت معاهم و ان شاء الله مش اخر تعامل"
    },
    {
        id: 12 , name: "Mohamed Abbas", lang: 'en',
        content: "El order w bgad el Quality 7elwa 2wi 2wi msa 100 % isa da me4 2a5er t3amol"
    },
    {
        id: 13 , name: "David Sameh", lang: 'ar',
        content: "حلو جدا جدا و الكواليتي ما شاء الله محترمة و شكرا على سرعة التوصيل و شكرا جدا على ذوقكم"
    },
    {
        id: 14 , name: "Dina Mohamed", lang: 'ar',
        content: "الجزمة وصلت و توحفة بجد و مكونتش اتوقع ان يبقا في ميدالية و لبيسة للجمع انا فرحت بيهم اكتر من الجزمة اصلا"
    },
    {
        id: 15 , name: "Hala Saleh", lang: 'ar',
        content: "الاوردر ممتاز و الشوز فعلا نضيفة و الهدايا حلوه اوي و ان شاء الله مش اخر مره اتعامل معاكوا"
    },
    {
        id: 16 , name: "Habiba", lang: 'ar',
        content: "أنا لسة مستلمة الأوردر بجد ميرسي جدا جدا على الحاجات الزيادة اللي جت معاها و على سرعة التوصيل بجد أحسن مكان اتعاملت معاه و الكواليتي تحفة💗💗💗💗"
    },
    {
        id: 17 , name: "Reda", lang: 'ar',
        content: "الاوردر عجبني جدا و الباكينج كمان زي الفل و كواليتي الكوتشي محترمة و كويسة و البروتيكتورز كمان كويس و بيحافظ على الكوتشي فعلا و شكرا و ان شائ الله مش اخر تعامل"
    }
]