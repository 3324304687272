import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "./useAuthContext";

import { NEW_BASE_URL } from "../constants/serv";

export const useSignup = () => {

    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    // errors backend validation
    const [userExists, setUserExists] = useState(null)
    const [emailInvalid, setEmailInvalid] = useState(null)

    const { dispatch } = useAuthContext()

    const navigate = useNavigate()

    const signup = async(data) => {
        const {name, phoneNo, email, password, city, address, building, floorNo, apt, landmark, workAddress, terms} = data
        setError(null)
        setIsLoading(true)

        const res = await fetch(`${NEW_BASE_URL}/api/signup`, {
            method: 'POST',
            body: JSON.stringify({name, phoneNo, email, password, city, address, building, floorNo, apt, landmark, workAddress, terms }),
            headers: { 'Content-Type': 'application/json'}
        })

        const jsonData = await res.json()

        if(!res.ok) {
            setIsLoading(false)
            setError(jsonData.error)
        }

        if(jsonData.error) {
            if(jsonData.error.includes('user already exists')) {
                setUserExists('You have already registered with this account. Do you mean to log in instead?')
            } else {
                setUserExists(null)
            }
            //console.log('---', jsonData.error)
        }

        if(jsonData.errors) {
            if(jsonData.errors.email.includes('invalid')) {
                setEmailInvalid('Invalid email, please enter a valid email!')
            } else {
                setEmailInvalid(null)
            }
        }

        /*
        if(jsonData.user) {
            navigate('/')
        }
        */

        if(res.ok) {
            // save to local storage
            localStorage.setItem('user', JSON.stringify(jsonData.user))
            localStorage.setItem('_token', JSON.stringify(jsonData.token))
            //console.log(jsonData.user)
            navigate('/')

            // update context
            dispatch({type: 'LOGIN', payload: jsonData})
            setIsLoading(false)
        }
    }

    return { signup, isLoading, error, userExists, emailInvalid }
}