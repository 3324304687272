import React from "react";
import { Container, Row, Col, Stack } from "react-bootstrap";

//import LegalAccordion from "../components/LegalInfoAccordion";
import FAQs from "../components/FAQs";
import PoliciesAccordion from "./Policies";

import {truckIcon, medalIcon, locationIcon, socksIcon, exchangeIcon} from '../constants/grabIcons'

import aboutImage5 from '../assets/images/ABBB-sneakers.png'
import aboutImage6 from '../assets/images/shoes-illustration-2.webp'
import aboutImage7 from '../assets/images/about-image-01.webp'
import valuLogo from '../assets/logos/valu.webp'
import msgBubble from '../assets/images/msg-bubble-hi.gif'
import founder from '../assets/images/founder-1.JPG'

const AboutUs = () => {

    return (
        <Container className="aboutus-page">
            <Row>
            
                <Col className="col-12 col-lg-12">
                    <h1 className="text-lg">OUR STORY</h1>
                </Col>
                <Col className="col-12 col-lg-12">
                    <h5 className="section-y-margin">FOR EVERY WALK...</h5>
                    <p>
                        Our mission is to help everyone who
                        loves to look unique by providing sneakers, of high quality, with their
                        own packaging, exactly like the original, and delivery on time.
                    </p>
                </Col>

            </Row>
            <br/>
          
            <Row>
                <Col  className="col-12 col-md-2"></Col>
                <Col className="col-12 col-md-4">
                    <div className="">
                        <Stack gap={4} className="key-features">
                            <div >
                                <strong>{medalIcon} First Mirror</strong>
                                <p> Mirror original premium quality.</p>
                            </div>
                            <div>
                                <strong>{locationIcon} All over Egypt</strong>
                                <p> We deliver to all parts of Egypt except Sinai</p>
                            </div>
                            <div>
                                <strong>{truckIcon} In 5 Days</strong>
                                <p> Fast, safe & guaranteed, we deliver within 5 days</p>
                            </div>
                        </Stack>
                    </div>
                </Col>
                <Col className="col-6 col-md-6">
                <div className="">
                        <Stack gap={4} className="key-features">
                        <div>
                            <img alt="valu" src={valuLogo} width={60} />
                            <p>Installments Through Valu For a Period of Up To 60 Months.</p>
                        </div>
                            <div>
                                <strong>{exchangeIcon} Return/Exchange</strong>
                                <p>Exchange or Return Within 48 Hours From The Date Of Receipt.</p>
                            </div>
                            <div >
                                <strong>{socksIcon} Comfort</strong>
                                <p> Comfort in every walk, for every day</p>
                            </div>
                        </Stack>
                    </div>
                  
                </Col>
            </Row>

          
            <br/>
            <Row>
                <div className="pt-5">
                    <Row className="title-row">
                        <h1 className="Lalezar">
                            <>GET TO KNOW OUR TEAM</>
                        </h1>
                        <h6 className="center">A MESSAGE FROM OUR FOUNDER</h6>
                    </Row>
                    <Row>
                        <Col className="col-12 col-md-3">
                            <div className="left-sec">
                                <div className="center img-w">
                                    <img alt="text bubble" src={msgBubble} width={80} />
                                </div>
                                <div className="center round-img-container">
                                    <img alt="founder" src={founder} className="round-img" width={150} />
                                </div>
                            </div>
                        </Col>
                        <Col className="col-12 col-md-9">
                            <div className="msg-container">
                                <p>Dear Customers,</p>
                                <p>Hi there,</p>
                                <br />
                                <p>
                                    Am Hussien Reda, I’m a Graphic designer and a founder of Canal walk
                                    I would like to introduce briefly  who we are and how we got this project started;
                                    We are canal walk and it's  an online shoe store, Started on Feb 2nd 2023, We knew
                                    that the market was competitive and challenging.
                                    From the beginning our target was to achieve the equation of best quality with reasonable prices,
                                    and this made us go down to the wholesalers who are everywhere so that we can bring the best product quality
                                    along with good acceptable prices and giving our customers highest backging experience that exists in the
                                    market as it was shipped to you from the brand store overseas itself
                                </p>
                                <br />
                                <p>
                                    Our ultimate goal is assisting our beloved customers to wear unique, rare comfy sneakers that's isn't available in Egyptian local stores with our premium service and quality.
                                </p>
                                <br />
                                <p>
                                    Our Vision is to build our own official show room store and have several branches in Egypt and customised our own brand shoe  with the same quality of the high end brands.
                                    With your support, our aims and achievements will grow.
                                </p>
                                <br />
                                <p className="text-end">Hussien Reda</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Row>
            <Row>
                <div className="divider-general">
                    <hr className="divider-line" />
                </div>
            </Row>
            <Row>
                <Col>
                    <FAQs />
                </Col>
            </Row>
            <Row>
                <div className="divider-general">
                    <hr className="divider-line" />
                </div>
            </Row>
            <Row>
                <Col>
                    <PoliciesAccordion />
                </Col>
            </Row>
        </Container>
    )
}


export default AboutUs;