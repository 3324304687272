import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import { BASE_URL } from "../constants/serv";

const ControlledCarousel = ({props}) => {

    const { sku, productImages} = props

    
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };


    let images = Object.values(productImages);
 
   
    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            {images.map((img ) => (
                <Carousel.Item key={img.uuid}>
                    <img alt={img.name} src={img.original_url} className='carousel-image'/>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default ControlledCarousel;