import { useState } from "react"
import { Row, Col, Stack, Button, Form, InputGroup } from "react-bootstrap"
import { BASE_URL , NEW_BASE_URL } from "../constants/serv"
import axios from "axios"

import { useNavigate } from "react-router-dom"
const HappyClientData = ({ props }) => {

    const navigate = useNavigate()
    const [prepareEdit, setPrepareEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [savedSuccess, setSavedSuccess] = useState(false)
    let { _id,  name, message } = props

  

    const [newName, setNewName] = useState(name)
    const [newMessage, setNewMessage] = useState(message)
  


    const editProductInfoTrigger = () => {
        setPrepareEdit(true)
    }


    const handleSaveChanges = () => {

        const saveChanges = async() => {
            setLoading(true)
            const formData = new FormData();
            formData.append('name', newName)
            formData.append('message', newMessage)
            
            
            axios.post(`${NEW_BASE_URL}/api/happy-clients/${_id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(res => {
                console.log('res', res)
                setSavedSuccess(true)
                navigate('/admin/homepage')
                /*
                if (res.status === 200) {
                    setTriggerLoad(!triggerLoad)
                }
                */
            }).catch(err => console.log('err', err))


           
        }
        
        saveChanges()
    }

    return (
        <div className="plain-wrapper pri-txt">
            {!prepareEdit ?
                <div>
                    <Row>
                        <Col className="col-10">
                            
                            <Row>
                                <Col className="col-12 col-md-10"><strong>{name}</strong></Col>
                            </Row>
                           
                        </Col>
                        <Col className="col-2">
                            <Button variant="light" size="sm"
                                onClick={editProductInfoTrigger}>
                                Edit
                            </Button>
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col>
                            <Stack>
                                <small><span className="grey">Message: </span><strong>{message}</strong></small>
                            </Stack>
                        </Col>
          
                    </Row>
                </div>
                :
                <div>
                    <Form>
                        
                        <Row className="py-1">
                          
                            <Col className="col-12 col-lg-9">
                                <Form.Control size="sm" type="text" placeholder="name"
                                    defaultValue={name} onChange={(e) => setNewName(e.target.value)} />
                            </Col>
                        </Row>
             
                        <br />
                        <Row className="py-1">
                            <Col className="col-6">
                                <div className="py-1">
                                    <Form.Control size="sm" type="text" placeholder="message"
                                        defaultValue={message} onChange={(e) => setNewMessage(e.target.value)} />
                                </div>
                               
                            </Col>
                           
                        </Row>
   
                        <br />
                        <Row>
                            { !savedSuccess ?
                                <Col className="text-end">
                                <Button size="sm" variant="success" disabled={loading}
                                onClick={handleSaveChanges}>Save Changes</Button>
                            </Col>
                            :
                            <Col className="text-end">
                                <p className="green">Successfully saved</p>
                                <small className="grey">Please refresh this page to view changes</small>
                            </Col>
                            }
                        </Row>
                    </Form>
                </div>
            }
        </div>
    )
}

export default HappyClientData