import React from "react";
import { Link } from "react-router-dom";

import { Container, Row, Col, Stack } from "react-bootstrap";
import Image from "react-bootstrap/Image";

import logoFooter from "../assets/logos/Logo-07.png"

import {tiktokIcon, facebookIcon, instagramIcon, whatsappIcon, mailIcon, phoneIcon} from '../constants/grabIcons'





const Footer = () => {

    return(
        <footer className="footer" >
            <div>
                <Container className="py-5">
                    <Row className="px-3 text-light">
                        <Col className="col-12 col-md-5 py-2">
                            <div className="text-start py-2">
                                <Image alt="logoFooter" src={logoFooter} width={240}/>
                            </div>
                            <div className="text-start" >
                                <p className="lead">CANALWALK. For Every Walk</p>
                            </div>
                            <h6 className="section-y-margin">CONTACT US</h6>
                            <Stack direction="horizontal" gap={4} >
                                <h5><a href="https://www.instagram.com/canalwalkstore?igsh=Zzg3OGx1ZTc3N3Zm&utm_source=qr" className="footer-link">{instagramIcon}</a></h5>
                                <h5><a href="https://www.facebook.com/canalwalkstore?mibextid=LQQJ4d" className="footer-link">{facebookIcon}</a></h5>
                                <h5><a href="https://api.whatsapp.com/message/7AUVCMIBPMSRB1?autoload=1&app_absent=0" className="footer-link">{whatsappIcon}</a></h5>
                                <h5><a href="https://www.tiktok.com/@canalwalkstore?_t=8iZDAj2ACZi&_r=1" className="footer-link">{tiktokIcon}</a></h5>
                            </Stack>
                            <div className="text-start" >
                                <p><a href="mailto:canalwalk13@gmail.com" className="footer-link">{mailIcon}  canalwalk13@gmail.com</a></p>
                                <p>{phoneIcon} +20 110 114 6146</p>
                            </div>
                        </Col>
                        <Col className="col-0 col-md-1 py-2">
                            <Row className="border-start">
                            </Row>
                        </Col>
                        <Col className="col-12 col-md-6 py-4">
                            <Row>
                                <Col className="col-6">
                                    <Stack gap={1} className="text-start">
                                        <h6>NAVIGATE</h6>
                                        <ul className="list-unstyled">
                                            <li><Link to={{pathname: "/products/:featured"}} state={{filterValue: 'featured'}} className="footer-link">New Arrivals</Link></li>
                                            <li><Link to={{pathname: "/products/:all"}} state={{filterValue: 'all'}} className="footer-link">Shop Products</Link></li>
                                            <li><a href="/about" className="footer-link">Our Story</a></li>
                                        </ul>
                                    </Stack>
                                </Col>
                                <Col className="col-6">
                                    <Stack gap={1} className="text-start">
                                        <h6>CUSTOMER SERVICE</h6>
                                        <ul className="list-unstyled">
                                            <li><a href="/about" className="footer-link">FAQs</a></li>
                                            <li><Link to={{pathname: "/policies"}} state={{filterKey:'2'}} className="footer-link">Return/Exchange Policy</Link></li>
                                            <li><Link to={{pathname: "/policies"}} state={{filterKey:'1'}} className="footer-link">Shipping Policy</Link></li>
                                            <li><Link to={{pathname: "/privay-policy"}} state={{filterKey:'1'}} className="footer-link">Privacy Policy</Link></li>
                                        </ul>
                                    </Stack>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
              <Row className="py-2">
                <Col className="col-12 text-center">
                  <div>
                    <small>Copyright © 2024 CANALWALK. All rights reserved</small>
                  </div>
                </Col>
              </Row>
            </div>
        </footer>
    )
}

export default Footer;
