import { NEW_BASE_URL } from "../constants/serv"

export const useDecreaseQty = () => {

    let exSizeSheet = []
    let modifiedSizeSheet = []

    const decreaseQty = async(purchasedProducts) => {

        const {id, count, sizeChosen} = purchasedProducts
        console.log(count, id, sizeChosen)

        const fetchSizeSheet = async() => {
            const response = await fetch(`${NEW_BASE_URL}/api/products/${id}`)
            const json = await response.json()

            if(response.ok) {
                exSizeSheet = JSON.parse(json.data.sizeSheet)
                console.log(exSizeSheet)
                const index = exSizeSheet.findIndex(item => item.size === Number(sizeChosen))

                modifiedSizeSheet = exSizeSheet.map((item, i) => {
                    if(index === i) {
                        return {...item, qty: item.qty-count}
                    } else {
                        return item
                    }
                })

            }

            return modifiedSizeSheet

        }

        const sizeSheetToFetch = await fetchSizeSheet()

        const updateSizeSheet = async() => {
            const res = await fetch(`${NEW_BASE_URL}/api/products/${id}/update_quantity`, {
                method: 'POST',
                body: JSON.stringify({sizeSheet: sizeSheetToFetch}),
                headers: { 'Content-Type': 'application/json'}
            })

            await res.json()
        }

        updateSizeSheet()

    }

    return {decreaseQty}
}