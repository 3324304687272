import axios from "axios"
import { useState } from "react"
import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap"
import { NEW_BASE_URL } from "../constants/serv"
import { useSKUGenerator } from "../customHooks/useSKUGenerator"
import { useNavigate } from "react-router-dom"

const AddNewReviewForm = () => {

   
    const navigate = useNavigate()
    // UI feedback
    const [loading, setLoading] = useState(false)
    const [isApproved, setIsApproved] = useState(false)

    const [avail, setAvail] = useState(true)
    const [feat, setFeat] = useState(false)
    //const [videoArr, setVideoArr] = useState([{}])


    const [name, setName] = useState("")
    const [order, setOrder] = useState("")
  
    const [video, setVideo] = useState([])
  


    const handleSaveProduct = (e) => {
        e.preventDefault()
        setLoading(true)

        // preparing the product object to send to db
       
        if (name.length === 0) {
            setName("NoName")
        }

        // generate SKU
        const data = { name, order }

        const saveProduct = () => {

            const formData = new FormData();
            formData.append('name', name)
            formData.append('order', order)
           
        
            // Append Product Images
            if(video.length > 0) {
              
                for (let i = 0; i < video.length; i++) {
                  //  console.log(video[i])
                    formData.append('video[]', video[i])
                }
            } else formData.append('video', video)

            // Append Preview Images
            
            // }
            // post to db
            axios.post(`${NEW_BASE_URL}/api/reviews`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(res => {
                console.log('res', res)
                setLoading(false)
                /*
                if (res.status === 200) {
                    setTriggerLoad(!triggerLoad)
                }
                */
                navigate('/admin/homepage')
            }).catch(err => console.log('err', err))


        }

        saveProduct()
    }

    return (
        <Container className="py-5">
            <Row>
                <Col className='col-12'>
                    <h4><strong>Add New Review</strong></h4>
                </Col>
            </Row>
            <Row>
                <Form encType="multipart/form-data">
                    <Row className="py-1">
                        <strong>Basic Info</strong>
                        <small className="grey tiny-txt">Please fill in all fields</small>
                    </Row>
                    <Row>
                        <Col className="col-12 col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Control type="text" name="name" placeholder="Review Name"
                                    onChange={e => setName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12 col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Control type="number" name="order" placeholder="Review Order"
                                    onChange={e => setOrder(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
        
      
                    <br />
    
                  
                    <Row>
                        <strong>video</strong>
                        <small className="grey tiny-txt">All images must be: .jpg or .png, Dimension are preffered in square, min: (450x450px), max: (700x700px).</small>
                    </Row>
                    <br />
                    <Row>
                        <Col className="col-12 col-lg-4">
                            <>
                                <strong className="grey">Review Video</strong>
                                <Form.Group className="mb-3" >
                                    <Form.Control type="file"  name="video"
                                        onChange={(e) => {
                                            setVideo(e.target.files)
                                            console.log(video, e.target.files)
                                        }}
                                    />
                                   

                                </Form.Group>
                            </>
                        </Col>
                        
                    
                        
                    </Row>
                    <div>

                    </div>
                    <div>


                    </div>
                    <Row>
                        <div className="text-end">
                            <Button variant="success" //disabled={loading}
                                onClick={e => handleSaveProduct(e)}>
                                Save 
                            </Button>
                        </div>
                    </Row>
                </Form>
            </Row>
        </Container>
    )
}

export default AddNewReviewForm