import { useState ,useEffect} from "react"
import { Row, Col, Stack, Button, Form, InputGroup } from "react-bootstrap"
import { BASE_URL , NEW_BASE_URL } from "../constants/serv"
import axios from "axios"

import { useNavigate } from "react-router-dom"
const ProductData = ({ props }) => {

    const navigate = useNavigate()
    const [prepareEdit, setPrepareEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [savedSuccess, setSavedSuccess] = useState(false)
    const [triggerToggle, setTriggerToggle] = useState(true)
    const [categories, setCategories] = useState()
    let { _id, sku, brand, name, price, discount, availability, featured, gender, category,category_id, color, material, sizeSheet } = props
    useEffect(() => {
        const fetchCategories = async () => {
            const res = await fetch(`${NEW_BASE_URL}/api/categories`)
            //console.log(res)
            var json = await res.json()
            json=json.data
            //console.log(json)

            if (res.ok) {
                setCategories(json)
            } else {
                console.log('error occurred', res)
            }
        }

        fetchCategories()
    }, [triggerToggle])
    sizeSheet = JSON.parse(sizeSheet);
    let newSizeArr = null
    if (sizeSheet.length > 10 && gender === 'women') {
        newSizeArr = sizeSheet.slice(0, 6)
    } else if (sizeSheet.length > 10 && gender === 'men') {
        newSizeArr = sizeSheet.slice(5, 11)
    } else {
        newSizeArr = sizeSheet
    }

    const [newBrand, setNewBrand] = useState(brand)
    const [newName, setNewName] = useState(name)
    const [newPrice, setNewPrice] = useState(price)
    const [newDiscount, setNewDiscount] = useState(discount)
    const [newAvail, setNewAvail] = useState(availability)
    const [newFeatured, setNewFeatured] = useState(featured)
    const [newGender, setNewGender] = useState(gender)
    const [newCategory, setNewCategory] = useState(category_id)
    const [newColor, setNewColor] = useState(color)
    const [newMaterial, setNewMaterial] = useState(material)
    const [newSizeSheet, setNewSizeSheet] = useState(sizeSheet)
    const [productImages, setProductImages] = useState([])
    const [previewImages, setPreviewImages] = useState([])
    const [previewVideo, setPreviewVideo] = useState()

    const editProductInfoTrigger = () => {
        setPrepareEdit(true)
    }

    const handleNewSizeSheet = (size, e) => {
        const index = newSizeSheet.findIndex(item => item.size === size)

        const newSizeArr = newSizeSheet.map((item, i) => {
            if (index === i) {
                return { ...item, qty: parseInt(e) }
            } else {
                return item
            }
        })

        setNewSizeSheet(newSizeArr)
    }

    const handleSaveChanges = () => {

        const saveChanges = async() => {
            setLoading(true)
            const formData = new FormData();
            formData.append('name', newName)
            formData.append('brand', newBrand)
            formData.append('category_id', newCategory)
            formData.append('gender', newGender)
            formData.append('price', Number(newPrice))
            formData.append('discount', Number(newDiscount))
            formData.append('featured', newFeatured)
            formData.append('color', newColor)
            formData.append('material', material)
            formData.append('availability', newAvail)
            // Append sizeSheet
            formData.append('sizeSheet', JSON.stringify(newSizeSheet))
            // Append Product Images
            if(productImages.length > 0) {
              
                for (let i = 0; i < productImages.length; i++) {
                  //  console.log(productImages[i])
                    formData.append('productImages[]', productImages[i])
                }
            } else formData.append('productImages', productImages)

            // Append Preview Images
            if(previewImages.length > 0) {
                for (let i = 0; i < previewImages.length; i++) {
                    formData.append('previewImages[]', previewImages[i])
                }
            } else formData.append('previewImages', previewImages)
            // Append Video
            formData.append('previewVideo[]', previewVideo)

            axios.post(`${NEW_BASE_URL}/api/products/${_id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(res => {
                console.log('res', res)
                setSavedSuccess(true)
                navigate('/admin/homepage')
                /*
                if (res.status === 200) {
                    setTriggerLoad(!triggerLoad)
                }
                */
            }).catch(err => console.log('err', err))
            
        }
        
        saveChanges()
    }

    return (
        <div className="plain-wrapper pri-txt">
            {!prepareEdit ?
                <div>
                    <Row>
                        <Col className="col-10">
                            <Row>
                                <Col>
                                    <small className="grey tiny-txt">{sku}</small>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12 col-md-2 grey"><strong>{brand}</strong></Col>
                                <Col className="col-12 col-md-10"><strong>{name}</strong></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small><span className="grey">Price: </span><strong>{price}</strong></small>
                                </Col>
                                <Col>
                                    <small><span className="grey">Discount: </span><strong>{discount}</strong></small>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="col-2">
                            <Button variant="light" size="sm"
                                onClick={editProductInfoTrigger}>
                                Edit
                            </Button>
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col>
                            <Stack>
                                <small><span className="grey">Gender: </span><strong>{gender}</strong></small>
                                <small><span className="grey">Category: </span><strong>{category}</strong></small>
                                <small><span className="grey">Color: </span><strong>{color}</strong></small>
                                <small><span className="grey">Material: </span><strong>{material}</strong></small>
                                <small><span className="grey">Availablility: </span>{availability ? <strong>in stock</strong> : <strong>out of stock</strong>}</small>
                                <small><span className="grey">Featured: </span>{featured ? <strong>Yes</strong> : <strong>No</strong>}</small>

                            </Stack>
                        </Col>
                        <Col className="border-left">
                            <Stack direction="horizontal" gap={3} className="grey">
                                <small>Size</small>
                                <small>Stock</small>
                            </Stack>
                            {newSizeArr !== null &&
                                newSizeArr.map((row, i) => (
                                    <div key={`${row.size}+${i}`}>
                                        <Stack direction="horizontal" gap={4}>
                                            <small><strong>{row.size}</strong></small>
                                            <small><strong>{row.qty}</strong></small>
                                        </Stack>
                                    </div>
                                ))
                            }
                        </Col>
                    </Row>
                </div>
                :
                <div>
                    <Form>
                        <Row className="grey py-1">
                            <Col><small className="tiny-txt">{sku}</small></Col>
                        </Row>
                        <Row className="py-1">
                            <Col className="col-12 col-lg-3">
                                <Form.Control size="sm" type="text" placeholder="brand"
                                    defaultValue={brand} onChange={(e) => setNewBrand(e.target.value)} />
                            </Col>
                            <Col className="col-12 col-lg-9">
                                <Form.Control size="sm" type="text" placeholder="name"
                                    defaultValue={name} onChange={(e) => setNewName(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="py-1">
                            <Col className="col-6">
                                <Form.Control size="sm" type="number" min={0} placeholder="price"
                                    defaultValue={price} onChange={(e) => setNewPrice(e.target.value)} />
                            </Col>
                            <Col className="col-6">
                                <Form.Control size="sm" type="number" min={0} placeholder="discount"
                                    defaultValue={discount} onChange={(e) => setNewDiscount(e.target.value)} />

                            </Col>
                        </Row>
                        <br />
                        <Row className="py-1">
                            <Col className="col-6">
                                <div className="py-1">
                                    <Form.Control size="sm" type="text" placeholder="gender"
                                        defaultValue={gender} onChange={(e) => setNewGender(e.target.value)} />
                                </div>
                                <div className="py-1">
                                
                                <Form.Select aria-label="Default select example" name="category"
                                    value={ category_id} onChange={e => setNewCategory(e.target.value)} 
                                >
                                    <option>-- category</option>
                                    {categories && categories.map((p, i) => (
                                        <option value={p.id} >{p.name}</option>
                                    ))}
  
                                </Form.Select>
                           
                                   
                                </div>
                                <div className="py-1">
                                    <Form.Control size="sm" type="text" placeholder="color"
                                        defaultValue={color} onChange={(e) => setNewColor(e.target.value)} />
                                </div>
                                <div className="py-1">
                                    <Form.Control size="sm" type="text" placeholder="material"
                                        defaultValue={material} onChange={(e) => setNewMaterial(e.target.value)} />
                                </div>
                                <div className="py-1">
                                    <Form.Check
                                        type="switch" id="custom-switch" label="Availability"
                                        defaultChecked={availability} onChange={() => setNewAvail(!availability)}
                                    />
                                </div>
                                <div className="py-1">
                                    <Form.Check
                                        type="switch" id="custom-switch" label="New Arrival ?"
                                        defaultChecked={featured} onChange={() => setNewFeatured(!featured)}
                                    />
                                </div>
                            </Col>
                            <Col className="col-6">
                                {sizeSheet.map(row => (
                                    <div key={row._id}>
                                        <div className="py-1">
                                            <InputGroup size="sm">
                                                <InputGroup.Text id="basic-addon1">{row.size}</InputGroup.Text>
                                                <Form.Control type="number" min={0}
                                                    placeholder="qty" aria-label="size" aria-describedby="basic-addon1"
                                                    defaultValue={row.qty} onChange={e => {
                                                        handleNewSizeSheet(row.size, e.target.value)
                                                    }}
                                                />
                                            </InputGroup>
                                        </div>
                                    </div>
                                ))}
                            </Col>
                        </Row>
                        <Row>
                        <Col className="col-12 col-lg-4">
                            <>
                                <strong className="grey">Product Images</strong>
                                <Form.Group className="mb-3" >
                                    <Form.Control type="file" multiple name="productImages"
                                        onChange={(e) => {
                                            setProductImages(e.target.files)
                                            console.log(productImages, e.target.files)
                                        }}
                                    />
                                    <small className="grey tiny-txt">6 images maximum</small>

                                </Form.Group>
                            </>
                        </Col>
                        <Col className="col-12 col-lg-4">
                            <>
                                <strong className="grey">Live Photos</strong>
                                <Form.Group className="mb-3">
                                    <Form.Control type="file" multiple name="previewImages"
                                        onChange={(e) => {
                                            setPreviewImages(e.target.files)
                                            console.log(previewImages, e.target.files)
                                        }}
                                    />
                                    <small className="grey tiny-txt">4 images maximum</small>
                                </Form.Group>
                            </>
                        </Col>
                        <Col className="col-12 col-lg-4">
                            <>
                                <strong className="grey">Preview Video</strong>
                                <Form.Group className="mb-3">
                                    <Form.Control type="file" name="previewVideo"
                                        onChange={e => {
                                            setPreviewVideo(e.target.files[0])
                                            console.log(e.target.files[0])
                                        }}
                                    />
                                    <small className="grey tiny-txt">Video must not exceed 20MB</small>
                                </Form.Group>
                            </>
                        </Col>
                    </Row>
                        <br />
                        <Row>
                            { !savedSuccess ?
                                <Col className="text-end">
                                <Button size="sm" variant="success" disabled={loading}
                                onClick={handleSaveChanges}>Save Changes</Button>
                            </Col>
                            :
                            <Col className="text-end">
                                <p className="green">Successfully saved</p>
                                <small className="grey">Please refresh this page to view changes</small>
                            </Col>
                            }
                        </Row>
                    </Form>
                </div>
            }
        </div>
    )
}

export default ProductData