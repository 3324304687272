import React from "react";

import { Container, Row } from "react-bootstrap";

const TitleHeader = ({title, titleFaded}) => {
    return(
        <Container className="title-header-container">
            <Row>
                <div className="container-bg">
                    <h1 className="title-header-faded">{titleFaded}</h1>
                </div>
                <h1 className="title-header">{title}</h1>
            </Row>
        </Container>
    )
}


export default TitleHeader;