import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";

import ProductCard from '../components/ProductCard'
import LoadingComponent from "../components/LoadingComponent";

import { Container, Row, Col, Nav, NavDropdown } from "react-bootstrap";

import { NEW_BASE_URL } from "../constants/serv";

const ShopProducts = () => {

    // getting the id of selected product
    const location = useLocation()
    let filter = location.state

    const [loading, setLoading] = useState(true)
    const [triggerToggle, setTriggerToggle] = useState(true)
    var [categoryFilter, setCategories] = useState([])

    const [categories, listCategories] = useState([])

    const fetchCategories = async () => {
        const res = await fetch(`${NEW_BASE_URL}/api/categories-filter`)
        //console.log(res)
        var json = await res.json()
        json=json.data
        //console.log(json)

        if (res.ok) {
            listCategories(json)
        } else {
            console.log('error occurred', res)
        }
    }
    
  
   // fetchCategories();
    
    // filtering
    //const categoryFilter = categories;
    const genderFilter = ['men', 'Men', 'MEN', 'women', 'Women', 'WOMEN', 'unisex']
    const brandFilter = [
        'nike', 'Nike', 'NIKE', 'Adidas', 'ADIDAS', 'adidas', 'jordan', 'Jordan',
        'JORDAN', 'new balance', 'New Balance', 'New balance', 'new Balance',
        'NEW BALANCE'
    ]
    // const categoryFilter = [
    //     'SNEAKERS', 'sneakers', 'Sneakers', 'socks', 'Socks', 'SOCKS',
    //     'crease-guards', 'crease protectors', 'Crease Protectors',
    //     'creases protectors'
    // ]

    const [filteredProducts, setFilteredProducts] = useState(null)

    const productsSorting = (oA, oB) => {
        if(!oA.availability) return 1
        else if(!oB.availability) return -1
        else return 0
    }

    useEffect(() => {
        fetchCategories();
        const fetchProducts = async () => {
            const res = await fetch(`${NEW_BASE_URL}/api/categories-filter`)
            if (res.ok) {
            var json = await res.json()
            json=json.data
            categoryFilter = json
            
            
            const response = await fetch(`${NEW_BASE_URL}/api/products`)
            var json = await response.json()
           

            if (response.ok) {
                
                json=json.data;
                if (genderFilter.some(v => filter.filterValue === v)) {
                    setFilteredProducts(json.filter(product => product.gender === filter.filterValue || product.gender === 'unisex'))
                } else if (brandFilter.some(v => filter.filterValue === v)) {
                    setFilteredProducts(json.filter(product => product.brand === filter.filterValue))
                } else if (filter.fiterType == 'category' && categoryFilter && categoryFilter.some(v => filter.filterValue === v)) {
                    setFilteredProducts(json.filter(product => product.category === filter.filterValue))
                } else if (filter.filterValue === 'featured') {
                    setFilteredProducts(json.filter(product => product.featured))
                } else {
                    setFilteredProducts(json)
                }

                setLoading(false)
            }
        }
            
        }

        fetchProducts()

    }, [filter.filterValue])

    return (
        <Container className="shop-products">
            <Row>
                <Col className="col-12 col-md-1"></Col>
                <Col className=" col-12 col-md-10 cards-wrapper">
                    <Row>
                        {
                            filter === null ?
                                <div className="center title-padding"><h1>Shop All Products</h1></div>
                                :
                                <div className="center title-padding"><h1>Shop {filter.filterValue}</h1></div>
                        }
                    </Row>
                    <Row>
                        <Nav className="justify-content-center filter-nav">
                            <Nav.Item>
                                <NavDropdown title="CATEGORY">
                                    <NavDropdown.Item>
                                        <Nav.Link as={Link} to={{ pathname: "/products/:all" }} state={{ filterValue: 'all' }}>All Categories</Nav.Link>
                                    </NavDropdown.Item>
                                    {categories && categories.map((p, i) => (
                                       
                                    <NavDropdown.Item className="nav-drop-item">
                                        <Nav.Link as={Link} to={{ pathname: "/products/:"+p }} state={{ filterValue: p, fiterType:'category' }}>{p}</Nav.Link>
                                    </NavDropdown.Item>
                                     ))}
                                </NavDropdown>
                            </Nav.Item>
                            <Nav.Item>
                                <NavDropdown title="GENDER">
                                    <NavDropdown.Item className="nav-drop-item">
                                        <Nav.Link as={Link} to={{ pathname: "/products/:men" }} state={{ filterValue: 'men' }}>Men</Nav.Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <Nav.Link as={Link} to={{ pathname: "/products/:women" }} state={{ filterValue: 'women' }}>Women</Nav.Link>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav.Item>
                            <Nav.Item>
                                <NavDropdown title="BRAND">
                                    <NavDropdown.Item className="nav-drop-item">
                                        <Nav.Link as={Link} to={{ pathname: "/products/:adidas" }} state={{ filterValue: 'Adidas' }}>Adidas</Nav.Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item >
                                        <Nav.Link as={Link} to={{ pathname: "/products/:nike" }} state={{ filterValue: 'Nike' }}>Nike</Nav.Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item >
                                        <Nav.Link as={Link} to={{ pathname: "/products/:jordan" }} state={{ filterValue: 'Jordan' }}>Jordan</Nav.Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item >
                                        <Nav.Link as={Link} to={{ pathname: "/products/:new-balance" }} state={{ filterValue: 'New Balance' }}>New Balance</Nav.Link>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav.Item>
                        </Nav>
                    </Row>
                    {!loading ?
                        <Row className="pb-5" >
                            {
                                filteredProducts ?
                                    <>
                                        {filteredProducts &&
                                            filteredProducts.sort(productsSorting).map((product) => (
                                                <Col className="col-6 col-lg-4 card-container" key={product._id}>
                                                    <ProductCard data={product} />
                                                </Col>
                                            ))}
                                    </>
                                    :
                                    <div className="center">
                                        <p className="grey">No products to view currently</p>
                                    </div>
                            }
                        </Row>
                        :
                        <div>
                            <LoadingComponent />
                        </div>
                    }
                </Col>
                <Col className="col-12 col-md-1"></Col>
            </Row>
            <br/>
        </Container>
    )
}

export default ShopProducts;