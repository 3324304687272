import { useEffect, useState } from "react"
import { Row, Col, Button, ButtonGroup, Modal, Form } from "react-bootstrap"
import { BASE_URL ,NEW_BASE_URL} from "../constants/serv"
import { deleteIcon } from "../constants/grabIcons"
import ShippingManagement from '../admin-dashboard/ShippingManagement';
import axios from "axios"
import { useNavigate } from "react-router-dom"

const PricingManagement = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [triggerToggle, setTriggerToggle] = useState(true)

    const [savedSuccess, setSavedSuccess] = useState(false)
  
    const [newDiscount, setNewDiscount] = useState(0)


    const [promocodes, setPromocodes] = useState()
    useEffect(() => {
        const fetchPromos = async () => {
            const res = await fetch(`${BASE_URL}/api/promocodes/all`)
            const json = await res.json()

            if (res.ok) {
                setPromocodes(json)
            }
        }

        fetchPromos()
    }, [triggerToggle])



    const handleSaveChanges = () => {

        const saveChanges = async() => {
            setLoading(true)
            const formData = new FormData();
            formData.append('discount', newDiscount)
          
            
            axios.post(`${NEW_BASE_URL}/api/bulk_discount`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(res => {
                console.log('res', res)
                setSavedSuccess(true)
                // navigate('/admin/homepage')
                
                if (res.status === 200) {
                    setTriggerToggle(!triggerToggle)
                    setLoading(false)
                }
                
            }).catch(err => console.log('err', err))


           
        }
        
        saveChanges()
    }



    // PROMOCODE
    //FIRST: Add new +
    const [newPromoModalShow, setNewPromoModalShow] = useState(false)
    const [newPromo, setNewPromo] = useState({
        code: 'xxx',
        value: 0
    })

    const addNewPromo = (promocode) => {
        //const promo = promocode
        const addPromo = async(promo) => {
            setLoading(true)
            const {code, value} = promo
            const res = await fetch(`${BASE_URL}/api/promocodes/`, {
                method: 'POST',
                body: JSON.stringify({code, value}),
                headers: { 'Content-Type': 'application/json'}
            })

            await res.json()

            if(res.ok) {
               setNewPromoModalShow(false)
               setTriggerToggle(!triggerToggle)
               setLoading(false)
            }
        }
        addPromo(promocode)
    }

    // SECOND: Delete promocode
    const [delModalShow, setDelModalShow] = useState(false) //delete promo modal
    const [promoToDel, setProductToDel] = useState(null)

    const openModalDel = (promo) => {
        setProductToDel(promo)
        setDelModalShow(true)
    }

    const handleDelete = (promo) => {
        setLoading(true)
        const id = promo._id
        const deleteOrder = async () => {
            const response = await fetch(`${BASE_URL}/api/promocodes/${id}`, {
                method: 'DELETE'
            })

            await response.json()

            if (response.ok) {
                setDelModalShow(false)
                setLoading(false)
                setTriggerToggle(!triggerToggle)
            }
        }

        deleteOrder()
    }

    return (
        <div>
             
            <div className='plain-wrapper'>
                <div className='plain-wrapper'>
                    <h4><strong>Pricing and Sales Managment</strong></h4>
                </div>
                <div className='border-wrapper'>
                    <Row>
                        <Col className='col-6'>
                            <h6><strong>Shipping Prices</strong></h6>
                            
                        </Col>
                        <ShippingManagement />
                        <Col className='col-6 text-end'>
                            <ButtonGroup>
                                <Button variant='outline-secondary' size='sm'>View</Button>
                                <Button variant='outline-secondary' size='sm'>Update</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </div>

                <div className='border-wrapper'>
                    <Row>
                        <Col className='col-6'>
                            <h6><strong>Bulk Discount</strong></h6>
                            <small>here you can apply bulk discount</small>
                        </Col>
                        <Col className='col-12 mt-3'>
                        <Form>
                        
                        <Row className="py-1">
                          
                            <Col className="col-12 col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Control type="text" name="discount" placeholder="Discount Value"
                                    onChange={e => setNewDiscount(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        </Row>
             
                        <br />
              
                        <Row>
                            { !savedSuccess ?
                                <Col className="text-end">
                                <Button size="sm" variant="success" disabled={loading}
                                onClick={handleSaveChanges}>Save Changes</Button>
                            </Col>
                            :
                            <Col className="text-end">
                                <p className="green">Successfully Applied</p>
                                
                            </Col>
                            }
                        </Row>
                    </Form>
                        </Col>
                    </Row>
                </div>
                {
                    <div className='border-wrapper'>
                        <Row>
                            <Col className='col-6'>
                                <h6><strong>Promocodes</strong></h6>
                                <small className='grey'>Current Promocodes</small>
                            </Col>
                            <Col className='col-6 text-end'>
                                <Button variant='success' size='sm' disabled={loading}
                                    onClick={() => setNewPromoModalShow(true)}>
                                    Add New +
                                </Button>
                            </Col>
                            <Modal show={newPromoModalShow} onHide={() => setNewPromoModalShow(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add New Promocode</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Row>
                                            <Col>
                                                <Form.Label>Code</Form.Label>
                                                <Form.Control placeholder={newPromo.code}
                                                    onChange={(e) => {
                                                        setNewPromo({code: e.target.value, value: newPromo.value})
                                                    }}
                                                />
                                                <small className="grey tiny-txt">note: case sensitive</small>
                                            </Col>
                                            <Col>
                                                <Form.Label>Value</Form.Label>
                                                <Form.Control placeholder={newPromo.value}
                                                    onChange={(e) => {
                                                        setNewPromo({code: newPromo.code, value: e.target.value})
                                                    }}
                                                />
                                                <small className="grey tiny-txt">note: accepts numbers only</small>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setNewPromoModalShow(false)}>
                                        Close
                                    </Button>
                                    <Button variant="success" onClick={() => addNewPromo(newPromo)}>
                                        Save Promocode
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Row>
                        <Row>
                            {promocodes && promocodes.map((promo, i) => (
                                <Col className="col-12 col-lg-4 border-all" key={promo._id}>
                                    <Row className=''>
                                        <Col className="col-1 py-2 left-mark">
                                            <small className="tiny-txt">{i + 1}</small>
                                        </Col>
                                        <Col className='col-7'>
                                            <div>
                                                <small>code: <strong className="pri-txt">{promo.code}</strong></small>
                                                <br />
                                                <small>value: <strong className="pri-txt">%{promo.value}</strong></small>
                                            </div>
                                        </Col>
                                        <Col className='col-4 text-end py-2'>
                                            <div>
                                                <Button variant='outline-danger' size='sm'
                                                    onClick={() => openModalDel(promo)}>
                                                    {deleteIcon}
                                                </Button>
                                            </div>
                                            <Modal show={delModalShow} onHide={() => setDelModalShow(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Delete Promocode</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <p>
                                                        Are you sure you want to delete this promocode? <br />
                                                        {promoToDel
                                                            ? <strong className="red">{promoToDel.code}</strong>
                                                            : <></>
                                                        }
                                                    </p>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={() => setDelModalShow(false)}>
                                                        Close
                                                    </Button>
                                                    <Button variant="danger" onClick={() => handleDelete(promoToDel)}>
                                                        Delete Promocode
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </Col>
                                    </Row>
                                </Col>
                            ))}
                        </Row>
                    </div>
                }
                <div className='border-wrapper under-const hidden'>
                    <Row>
                        <Col className='col-6'>
                            <h6><strong>All Items Sale</strong></h6>
                            <small>Current sale is:</small>
                            <div className=''>
                                <p></p>
                            </div>
                        </Col>
                        <Col className='col-6 text-end'>
                            <Button variant='outline-secondary' size='sm'>Change</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default PricingManagement