import { useState } from 'react';
import { Row, Col, Button, Modal, Form, InputGroup } from 'react-bootstrap';

import { BASE_URL } from "../constants/serv";

import egyFlag from '../assets/images/egypt.png'

function RequestItem(props) {

    const [sentSuccessful, setSentSuccessful] = useState(false)

    const [userName, setUserName] = useState()
    const [mail, setMail] = useState()
    const [phone, setPhone] = useState()
    const [productName, setProductName] = useState()
    const [brand, setBrand] = useState()
    const [color, setColor] = useState()
    const [size, setSize] = useState()
    const [comment, setComment] = useState()
    const [contactMe, setContactMe] = useState(true)

    const handleSubmit = () => {
        const data = {
            userName, mail, phone,
            productName, brand, color, size,
            comment, contactMe,
            requestType: 'wishlist-recommendation'
        }

        const sendWishlistRequest = async (data) => {
            const res = await fetch(`${BASE_URL}/api/requests/wishlist`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' }
            })

            const json = await res.json()

            if (res) {
                if (res.ok) {
                    setSentSuccessful(true)
                }
            }

            if (!res) {
                //console.log(json.error)
            }
        }

        sendWishlistRequest(data)
        //props.onHide()
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered scrollable={true} className='modal-container'
        >
            <Modal.Header closeButton className='my-header'>
                <Modal.Title id="contained-modal-title-vcenter" className='request-title'>
                    <h5>Request a Specific Product</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body' >
                <p className='paragh'>Please fill out this recommendation form, so that we can get you your desired footwear!</p>
                <Form>
                    <Row>
                        <Col className='col-12'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>My name is</Form.Label>
                                <Form.Control
                                    type="text" required
                                    placeholder="Full Name"
                                    autoFocus value={userName}
                                    onChange={e => setUserName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='col-12 col-lg-6'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="name@example.com"
                                    value={mail}
                                    onChange={e => setMail(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col className='col-12 col-lg-6'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                <Form.Label>Phone no.</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text><img alt='egypt' src={egyFlag} width={15} /></InputGroup.Text>
                                    <Form.Control type="text" placeholder="+20"
                                        value={phone}
                                        onChange={e => setPhone(e.target.value)} />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='col-12'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                <Form.Label>And I want</Form.Label>
                                <Form.Control
                                    type="text" required
                                    placeholder="Name of the sneakers"
                                    value={productName}
                                    onChange={e => setProductName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='col-4'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                <Form.Label>Brand</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Brand"
                                    value={brand}
                                    onChange={e => setBrand(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col className='col-4'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                                <Form.Label>Color</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="choose color"
                                    value={color}
                                    onChange={e => setColor(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col className='col-4'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput7">
                                <Form.Label>Size</Form.Label>
                                <Form.Select aria-label="Default select example"
                                    value={size}
                                    onChange={e => setSize(e.target.value)}>
                                    <option value="36">36</option>
                                    <option value="37.5">37.5</option>
                                    <option value="38">38</option>
                                    <option value="39">39</option>
                                    <option value="40">40</option>
                                    <option value="41">41</option>
                                    <option value="42">42</option>
                                    <option value="43">43</option>
                                    <option value="44">44</option>
                                    <option value="45">45</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Other notes</Form.Label>
                            <Form.Control as="textarea" rows={2} placeholder='Comments...'
                                value={comment}
                                onChange={e => setComment(e.target.value)} />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3">
                            <Form.Check
                                type="switch" defaultChecked
                                id="custom-switch"
                                label="Contact me when the product is available"
                                value={contactMe}
                                onChange={e => {
                                    if (e.target.checked) {
                                        setContactMe(true)
                                    } else {
                                        setContactMe(false)
                                    }
                                }}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {!sentSuccessful ?
                    <>
                        <Button id='btn-sec-top-left' onClick={props.onHide}>
                            Cancel
                        </Button>
                        <Button id='btn-pri-top-left' onClick={handleSubmit}>
                            Submit Request
                        </Button>
                    </>
                    :
                    <>
                        <Row>
                            <h6 className='green'>Request was sent Successfully!</h6>
                        </Row>
                        <Button id='btn-sec-top-left' onClick={props.onHide}>
                            Continue Shopping
                        </Button>
                    </>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default RequestItem;