import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../context/CartContext";

import { Container, Row, Col, Stack } from "react-bootstrap";

import { NEW_BASE_URL } from "../constants/serv";

// fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
const trashIcon = <FontAwesomeIcon icon={faTrash} />


const CartProductCard = (props) => {

    const navigate = useNavigate()

    let {id, name, price, discount, gender,productImage} = props.data
    const  sizeChosen  = props.sizeChosen
    const  count  = props.count

    const [ productInfo, setProductInfo ] = useState()

    useEffect(() => {
        const fetchProducts = async () => {
            const response = await fetch(`${NEW_BASE_URL}/api/products/${id}`)
            var json = await response.json()
            json=json.data;
            //console.log('response',response, json)

            if(response.ok) {
                setProductInfo(json)
            }
        }

        fetchProducts()
    },[])



    const {dispatch} = useContext(CartContext)

    // let totalPerProduct = price * cartItemsCount
    const [cartItemCount , setCartItemCount] = useState(0)

    const handleCart = ({type, id, count})=> {
        dispatch({type, id, sizeChosen, count, name, gender, price, discount})
        setCartItemCount(count)
    }

    return(
        <Container className="cart-product-card shadow-light-out">
            { productInfo &&
            <Row>
                <Col className="col-12 col-md-7 clickable">
                    <Stack direction="horizontal" gap={3} className="main">
                        <div className="img-wrapper">
                            <img alt='product' src={productInfo.productImage}
                                onClick={()=> navigate(`/:${productInfo._id}`, {state: {productId: productInfo._id}})}
                                className="clickable"/>
                        </div>
                        <div className="info-container">
                            <div className="info-stack">
                                <p>Brand: <strong>{productInfo.brand}</strong></p>
                                <p><strong>{productInfo.name}</strong></p>
                                <p>Size: <strong>{sizeChosen}</strong></p>
                                {productInfo.availability ? <small>In Stock</small> : <small>Out of Stock</small>}
                            </div>
                        </div>
                    </Stack>
                </Col>
                <Col className="col-12 col-md-5">
                    <Row>
                        <Col className="price-sec">
                            <Stack direction="horizontal" gap={4}>
                                <div>
                                    <p>Price <small>(EGP)</small></p>
                                    { productInfo.discount != 0 ?
                                        <Stack direction="horizontal" gap={2}>
                                            <p className="product-price">{productInfo.price - productInfo.discount}</p>
                                            <p className='product-price-off'>{productInfo.price}</p>
                                        </Stack>
                                    :
                                        <Stack direction="horizontal" gap={2}>
                                            <p className="product-price">{productInfo.price - productInfo.discount}</p>
                                        </Stack>
                                    }
                                </div>
                                <div>
                                    <p>QTY</p>
                                    <strong>{count}</strong>
                                </div>
                                <div>
                                    <p>Total <small>(EGP)</small></p>
                                    <strong>{(productInfo.price-productInfo.discount)*count}</strong>
                                </div>
                            </Stack>
                        </Col>
                    </Row>
                    <Row>
                        <div className="edit-btn-container">
                            <Stack direction="horizontal">
                                <div>
                                    <button className="link-btn-del"
                                        onClick={()=> {handleCart({type: 'DELETE_FROM_CART', id, count:0})}}>
                                        <small><span>{trashIcon}</span> DELETE</small>
                                    </button>
                                </div>
                            </Stack>
                        </div>
                    </Row>
                </Col>
            </Row>
            }
        </Container>
    )
}


export default CartProductCard;