
export const cartReducer = (state, action) => {
    //let index = state.items.findIndex(item => item.id === action.id)
    switch(action.type) {
        case 'ADD_TO_CART':
            //console.log(action, state)
            state = [
                {
                    id: action.id,
                    name: action.name,
                    count: action.count,
                    sizeChosen: action.sizeChosen,
                    price: action.price,
                    discount: action.discount,
                    gender: action.gender
                }, ...state]
            //console.log('add to cart', state)
            return [...state];
        case 'REMOVE_FROM_CART':
            //console.log(action)
            state = state.filter(i => i.id !== action.id)
            //console.log('remove from cart', state)
            return [...state];
        case 'DELETE_FROM_CART':
            //console.log(action)
            state = state.filter(i => i.id !== action.id)
            //console.log('delete cart', state)
            return [...state];
        case 'EMPTY_CART':
            state = []
            //state=inCart
            return [...state]
        default:
            return state
    }

}


/*
item: {
                        itemName: state.items.item.itemName,
                        itemPrice: state.items.item.itemPrice,
                        itemDiscount: state.items.item.itemDiscount,
                        itemGender: state.items.item.itemGender,
                    }

*/