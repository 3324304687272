import React from "react";
import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import ProductInfo from "./ProductInfo";
import Carousel from "./Carousel";

const SizeSelectModal = (sizeModalProps) => {

    return(
        <Modal {...sizeModalProps} aria-labelledby="contained-modal-title-vcenter"
        size="lg" className="modal-container size-select-modal">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="grid-example">
            <Container>
                <Row>
                    <Col className="col-12 col-lg-6"><Carousel props={sizeModalProps.props} /></Col>
                    <Col className="col-12 col-lg-6"><ProductInfo props={sizeModalProps.props}/></Col>
                </Row>
            </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button id="btn-pri-top-left" onClick={sizeModalProps.onHide}>
                    Continue Shopping
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SizeSelectModal;