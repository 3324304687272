import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button} from "react-bootstrap";

import emptyCartIcon from '../assets/images/empty-cart.png'

import { useAuthContext } from "../customHooks/useAuthContext";

// components
import CartProductCard from "../components/CartProductCard";
import { CartContext } from "../context/CartContext";
import { BASE_URL } from "../constants/serv";

const CartPage = () => {

    const navigate = useNavigate()

    // context
    const { itemsInCart } = useContext(CartContext)
    const { user } = useAuthContext()

    /*

    const [ productsInCart, setProductsInCart ] = useState()

    useEffect(() => {
        const fetchProducts = async (id) => {
            const response = await fetch(`${BASE_URL}/api/products/${id}`)
            const json = await response.json()

            //console.log('response',response, json)

            if(response.ok) {
                setProductsInCart(json)
                //setSubtotal([json.price, ...subtotal])
                //setInCart([...inCart, json.filter(i => i._id === itemsInCart.id)])
                //console.log('--------', productsInCart)
            }
        }

        itemsInCart.forEach((product) => fetchProducts(product.id))

    },[itemsInCart])

    */

    //console.log(inCart)
    // calculate subtotal & discount
    //const inCart = itemsInCart.filter(i => i.count !==0)

    let defaultInCartQty = 0
    let subtotalValue = 0
    let discountValue = 0

    itemsInCart.forEach(i => {
        subtotalValue+= i.count*i.price
        discountValue+= i.count*i.discount
        defaultInCartQty+= Number(i.count)
    })

    //console.log('sub-total',subtotal)

    const checkout = () => {
        if(user) {
            navigate('/checkout')
        } else {
            navigate('/login')
        }
    }


    return (
        <Container className="cart-page">
            <Row className="title">
                <h1> SHOPPING CART</h1>
            </Row>
            {itemsInCart.length === 0
            ?
            <div className="center empty-cart">
                <img alt="empty cart" src={emptyCartIcon} width={150}/>
                <h5>Your cart is empty!</h5>
                <small className="grey">Looks like you have not added anything into your cart!</small>
                <p className="pt-3">Start exploring our categories now!</p>
                <Col>
                    <Button id="btn-pri-top-left" onClick={()=> navigate(`/products/:all` , {state: {filterValue: 'all'}})}>
                        EXPLORE
                    </Button>
                </Col>
            </div>
            :
            <Row>
                <p className="center"> Number of items: <strong>{defaultInCartQty}</strong></p>
                <div className="cart-page-body">
                    <Row>
                        <Col className="col-12 col-lg-1"></Col>
                        <Col className="col-12 col-lg-10">
                            <Row>
                                
                                {itemsInCart.map(product => {
                                    return(
                                        <CartProductCard
                                            key={`${product.id}+${product.sizeChosen}`}
                                            count={product.count}
                                            sizeChosen={product.sizeChosen}
                                            id={product.id}
                                            data={product}
                                        />
                                )})}
                            </Row>
                        </Col>
                        <Col className="col-12 col-lg-1"></Col>
                    </Row>
                </div>
                {
                    <Row className="sticky-checkout shadow-light-out">
                        <p className="center">Subtototal: <strong>EGP {subtotalValue - discountValue}</strong></p>
                        <small className="center grey">Shipping is calculated on checkout</small>
                        <Button id="btn-pri-top-left" className="checkout-btn"
                        onClick={checkout}>
                            CHECKOUT
                        </Button>
                    </Row>
                }
            </Row>
            }
        </Container>
    )
}

export default CartPage;