import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useLogin } from "../customHooks/useLogin";

const Login = () => {

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string().min(8, 'Password must be 8 charachters minimum').required('Required')
    })

    const navigate = useNavigate()

    const { login, isLoading, error, emailInvalid, passwordInvalid } = useLogin()

    //console.log(isLoading)
    const handleSubmit = async(e) => {
        await login(e)
    }

    return (
        <Container className="login-page">
            <Row>
                <Col className="col-12 col-lg-8">
                    <div className="login-container shadow-light-out">
                        <h3><strong>Log in</strong></h3>
                        <div >
                            <p className="marg-0">First time?</p>
                            <p>Create your account <a href="/SignUp" className="link-style">SIGN UP</a></p>
                        </div>
                        <br/>
                        <Formik
                            initialValues={{email: '', password: ''}}
                            validationSchema={ LoginSchema }
                            onSubmit={ values => { handleSubmit(values) }}
                            >
                            {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row className="input-group">
                                    <Form.Group>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email" name="email" placeholder="name@example.com" autoFocus
                                            value={values.email} onChange={handleChange} onBlur={handleBlur}
                                            isInvalid={!!errors.email && touched.email}
                                        />
                                        {errors.email && touched.email ? <small className="form-errors">{errors.email}</small> : null }
                                        { emailInvalid === null ? <></> : <small className="form-errors">{emailInvalid}</small> }
                                    </Form.Group>
                                </Row>
                                <Row className="input-group">
                                    <Form.Group>
                                        <Form.Label htmlFor="inputPassword5">Password</Form.Label>
                                        <Form.Control
                                            type="password" name="password" id="inputPassword5"
                                            aria-describedby="passwordHelpBlock"
                                            value={values.password} onChange={handleChange} onBlur={handleBlur}
                                            isInvalid={!!errors.password && touched.password && passwordInvalid !== null}
                                        />
                                        { errors.password && touched.password ? <small className="form-errors">{errors.password}</small> : null }
                                        { passwordInvalid === null ? <></> : <small className="form-errors">{passwordInvalid}</small> }
                                    </Form.Group>
                                </Row>
                                <Row className="hidden">
                                    <a href="/" className="link-style hidden">
                                        <small>Forgot your password?</small>
                                    </a>
                                </Row>
                                <div className="login-btn-container">
                                    <Button type="submit" id="btn-pri-top-left" disabled={isLoading}>
                                        LOG IN
                                    </Button>
                                </div>
                            </Form>
                            )}
                        </Formik>
                    </div>
                </Col>
                <Col className="col-12 col-lg-4">
                    <div className="login-container shadow-light-out">
                        <p>Create an account instead?</p>
                        <Button id="btn-sec-top-left" onClick={() => navigate('/signup')}>
                            SIGN UP
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Login;