import React from "react"
import { Container, Accordion } from "react-bootstrap"

import { useState } from "react"
import SizeChart from "./SizeChart"
import { Link } from "react-router-dom"

const FAQs = () => {

    const [modalShow, setModalShow] = useState(false);

    return (
        <Container>
            <h4>FAQs</h4>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Mirror and original?</Accordion.Header>
                    <Accordion.Body>Mirror Original Premium Quality</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>I dont know my size, how do I know it?</Accordion.Header>
                    <Accordion.Body>
                        <p>Find your size:
                            <button className="link-btn"
                                onClick={() => setModalShow(true)}>
                                size shart
                            </button>
                        </p>
                        <SizeChart
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Shoes come with box and everything?</Accordion.Header>
                    <Accordion.Body>Yes! they come with the box and all its inclusions, if the shoes have extra needs</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>If I get two, can you get a discount?</Accordion.Header>
                    <Accordion.Body>Unfortunately, no</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Where are you located?</Accordion.Header>
                    <Accordion.Body>We are an online store only</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Can I order two sizes, decide which one is best, and return the size that is not right?</Accordion.Header>
                    <Accordion.Body>No! See our <Link to={{pathname: "/policies"}} state={{filterKey:'2'}} className="link-style"><strong>return & exchange policy</strong></Link></Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>If the shoes come but the size is not right, what should I do?</Accordion.Header>
                    <Accordion.Body>We will respond with receipt, exchange and return policies</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>How long does shipping take?</Accordion.Header>
                    <Accordion.Body>Within a maximum of 5 days. Learn more about our <Link to={{pathname: "/policies"}} state={{filterKey:'1'}} className="link-style"><strong>shipping policy</strong></Link></Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container>
    )
}

export default FAQs