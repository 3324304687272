//import react from 'react';
import { Container, Row, Col, Tab, Tabs, Button } from 'react-bootstrap';
import { useAuthContext } from '../customHooks/useAuthContext';

// components
import Orders from '../admin-dashboard/Orders';
import ProductsManagement from '../admin-dashboard/ProductsManagment';
import CategoryManagement from '../admin-dashboard/CategoryManagement';
import ReviewsManagement from '../admin-dashboard/ReviewsManagement';
import PricingManagement from '../admin-dashboard/PricingManagment';
import HappyClientManagement from '../admin-dashboard/HappyClientManagement';
import WebsiteStyling from '../admin-dashboard/WebsiteStyling';
import Requests from '../admin-dashboard/Requests';
import { useLogout } from '../customHooks/useLogout';
import { ADMIN_ID } from '../constants/serv';

//import Login from '/Login';


const AdminBoardHome = () => {

    const { user } = useAuthContext()
    const { logout } = useLogout()

    const handleLogout = () => {
        logout()
    }

    return (
        <div>

            <Container className='admin-home'>
                {user &&
                    <Row className='admin-banner shadow-light-out'>
                        <Col className='col-8'>
                            <small>Logged in as: <strong>{user.name}</strong></small>
                        </Col>
                        <Col className='col-4 text-end'>
                            <Button variant='dark' size='sm'
                                onClick={handleLogout}>
                                <small>Logout</small>
                            </Button>
                        </Col>
                    </Row>
                }

                <Row>
                    <h1 className='lalezar center pt-5'>Admin Dashboard</h1>
                </Row>

                {user ?
                    <div>
                        <Row>
                            <div>

                            </div>
                        </Row>
                        {user._id === ADMIN_ID ?
                            <div>

                                <br />
                                <Row>
                                    <Tabs defaultActiveKey="orders" className="all-tabs admin-tab" fill>
                                        <Tab eventKey="orders" title='Orders' className=''>
                                            <Orders />
                                        </Tab>
                                        <Tab eventKey="requests" title='Requests'>
                                            <Requests />
                                        </Tab>
                                        <Tab eventKey="categories" title='Categories Management'>
                                            <CategoryManagement />
                                        </Tab>
                                        <Tab eventKey="products" title='Products Managment'>
                                            <ProductsManagement />
                                        </Tab>
                                        <Tab eventKey="sales" title="Pricing Managment">
                                            <PricingManagement />
                                        </Tab>
                                        <Tab eventKey="site" title='Media and Marketing'>
                                            <WebsiteStyling />
                                        </Tab>
                                        <Tab eventKey="happy-clients" title='Happy Clients'>
                                            <HappyClientManagement />
                                        </Tab>
                                        <Tab eventKey="reviews" title='Reviews Management'>
                                            <ReviewsManagement />
                                        </Tab>
                                    </Tabs>
                                </Row>
                            </div>
                            :
                            <div className='full-page-height py-5'>
                                <p className='center'>You are not authorized to view this page</p>
                            </div>
                        }
                    </div>
                    :
                    <div className='full-page-height py-5'>
                        <p className='center'>Please Login first! <strong><a href='/login' className='link-style'>Login</a></strong></p>
                    </div>
                }
            </Container>
        </div>
    )
}

export default AdminBoardHome