import { v4 as uuid } from "uuid";

export const useSKUGenerator = () => {

    let sku
    const generateSKU = (data) => {

        const {category, brand, name, gender} = data

        const charsToSkip = ['/', '-', '_', '!', '`', '%', '*', '(', ')', '[', ']', '"', ]

        // SKU format: first-second-third-num
        // ex: FIRS-SECO-THIR-6D49F2

        const first = category.substring(0,5)

        let second
        if(brand) {
            second = brand.substring(0,3)+gender.substring(0,1)
        } else {
            second = 'none'.substring(0,3)+gender.substring(0,1)
        }

        let thirdArr = []
        const arr = name.split(' ')
        for (let i=0; i< arr.length; i++) {
            if(arr[i] !== brand && arr[i] !== gender && !charsToSkip.some(e => arr[i].includes(e)) ) {
                thirdArr[i] = arr[i].substring(0,1)
            }
        }

        const third = thirdArr.join("").substring(0,6)

        const num = uuid().slice(0,6)

        const skuConcat = `${first}-${second}-${third}-${num}`
        sku = skuConcat.toUpperCase()

        return sku

    }

    return { generateSKU }
}