import React from "react";
import { useSignup } from "../customHooks/useSignup";

import ReCAPTCHA from "react-google-recaptcha";

import { SHIPPING } from '../shippingData'

import { Container, Col, Row, Form, InputGroup, Button } from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';

import egyFlag from '../assets/images/egypt.png'
import shoesGif from '../assets/images/giphy.gif'
//import shoes2Gif from '../assets/images/giphy3.gif'

const SignUp = () => {

    const phoneNoRegExp = /^(\+201|01|00201)[0-2,5]{1}[0-9]{8}/g

    let governorates = []
    governorates = SHIPPING.map(o => o.region)

    const SignupSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
        phoneNo: Yup.string().matches(phoneNoRegExp, 'Invalid phoneNo number').max(14, 'Invalid phoneNo number').required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string().min(8, 'Password must be 8 charachters minimum').required('Required'),
        city: Yup.string().required('Required').oneOf(governorates, 'incorrect city value'),
        address: Yup.string().required('Required'),
        building: Yup.string().required('Required'),
        floorNo: Yup.string(),
        apt: Yup.string(),
        landmark: Yup.string(),
        workAddress: Yup.string(),
        terms: Yup.bool().required().oneOf([true], 'terms must be accepted'),
    })

    const { signup, isLoading, userExists, emailInvalid } = useSignup()

    const handleSubmit = async (e) => {
        await signup(e)
    }

    const onChangeReCAPTCHA = (value) => {
        console.log("Captcha value:", value);
    }

    return (
        <Container className="signup-page">
            <Row className="signup-form-container">
                <Col className="col-12 col-lg-8 form-col shadow-light-out">
                    <h3><strong>Create an Account</strong></h3>
                    <div >
                        <p>Already have an account? <a href="/login" className="link-style">LOG IN</a></p>
                    </div>
                    <br />
                    <Formik
                        initialValues={{
                            name: '', phoneNo: '', email: '', password: '',
                            city: '', address: '', building: '', floorNo: '',
                            apt: '', landmark: '', workAddress: '', terms: '',
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={values => {
                            handleSubmit(values)
                        }}>

                        {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <h5 className="grey">Personal Info</h5>
                                    <Col className='col-12 col-lg-6'>
                                        <Form.Group className="mb-3" controlId="nameId">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type="text" name="name" placeholder="Full Name" autoFocus
                                                value={values.name} onChange={handleChange} onBlur={handleBlur}
                                                isInvalid={!!errors.name && touched.name}
                                                className={errors.name && touched.name ? "form-errors" : "form-valid"} />
                                            {errors.name && touched.name ? <small className="form-errors">{errors.name}</small> : null}
                                        </Form.Group>
                                    </Col>
                                    <Col className='col-12 col-lg-6'>
                                        <Form.Group className="mb-3" controlId="phoneNoNoId">
                                            <Form.Label>phone no.</Form.Label>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text><img alt='egypt' src={egyFlag} width={20} /></InputGroup.Text>
                                                <Form.Control
                                                    name="phoneNo" placeholder="+20"
                                                    value={values.phoneNo} onChange={handleChange} onBlur={handleBlur}
                                                    isInvalid={!!errors.phoneNo && touched.phoneNo}
                                                    className={errors.phoneNo && touched.phoneNo ? "form-errors" : "form-valid"} />
                                            </InputGroup>
                                            {errors.phoneNo && touched.phoneNo ? <small className="form-errors">{errors.phoneNo}</small> : null}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-12 col-lg-6'>
                                        <Form.Group className="mb-3" controlId="emailId">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control
                                                type="email" name="email" placeholder="name@example.com"
                                                value={values.email} onChange={handleChange} onBlur={handleBlur}
                                                isInvalid={!!errors.email && touched.email}
                                                className={errors.email && touched.email ? "form-errors" : ""}
                                            />
                                            {errors.email && touched.email ? <small className="form-errors">{errors.email}</small> : null}
                                            {emailInvalid && <small className="form-errors">{emailInvalid}</small>}
                                        </Form.Group>
                                    </Col>
                                    <Col className="">
                                        <Form.Group>
                                            <Form.Label htmlFor="inputPassword5">Password</Form.Label>
                                            <Form.Control
                                                type="password" name="password" id="inputPassword5"
                                                aria-describedby="passwordHelpBlock"
                                                value={values.password} onChange={handleChange} onBlur={handleBlur}
                                                isInvalid={!!errors.password && touched.password}
                                                className={errors.password && touched.password ? "form-errors" : ""}
                                            />
                                            {errors.password && touched.password ? <small className="form-errors">{errors.password}</small> : null}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <br />
                                <h5 className="grey">Address</h5>
                                <Row>
                                    <Col className='col-12 col-lg-4'>
                                        <Form.Group className="mb-3" controlId="AddressId">
                                            <Form.Label>City</Form.Label>
                                            <Form.Select
                                                type="text" name="city" placeholder="City/Area"
                                                value={values.city} onChange={handleChange} onBlur={handleBlur}
                                                isInvalid={!!errors.city && touched.city}
                                                className={errors.city && touched.city ? "form-errors" : ""}
                                            >
                                                <option>choose city</option>
                                                {SHIPPING.map((o) => (
                                                    <option key={o.id}>{o.region}</option>
                                                ))}
                                            </Form.Select>
                                            {errors.city && touched.city ? <small className="form-errors">{errors.city}</small> : null}
                                        </Form.Group>
                                    </Col>
                                    <Col className='col-12 col-lg-8'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control
                                                type="text" name="address" placeholder="Address details"
                                                value={values.address} onChange={handleChange} onBlur={handleBlur}
                                                isInvalid={!!errors.address && touched.address}
                                                className={errors.address && touched.address ? "form-errors" : ""}
                                            />
                                            {errors.address && touched.address ? <small className="form-errors">{errors.address}</small> : null}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-4'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                            <Form.Control
                                                type="text" name="building" placeholder="Building no."
                                                value={values.building} onChange={handleChange} onBlur={handleBlur}
                                                isInvalid={touched.building && !!errors.building}
                                                className={errors.building && touched.building ? "form-errors" : ""}
                                            />
                                            {errors.building && touched.building ? <small className="form-errors">{errors.building}</small> : null}
                                        </Form.Group>
                                    </Col>
                                    <Col className='col-4'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                                            <Form.Control
                                                type="text" name="floorNo" placeholder="floor no."
                                                value={values.floorNo} onChange={handleChange} onBlur={handleBlur}
                                                className={errors.floorNo && touched.floorNo ? "form-errors" : ""}
                                            />
                                            {errors.floorNo && touched.floorNo ? <small className="form-errors">{errors.floorNo}</small> : null}
                                        </Form.Group>
                                    </Col>
                                    <Col className='col-4'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput7">
                                            <Form.Control
                                                type="text" name="apt" placeholder="Apt"
                                                value={values.apt} onChange={handleChange} onBlur={handleBlur}
                                                className={errors.apt && touched.apt ? "form-errors" : ""}
                                            />
                                            {errors.apt && touched.apt ? <small className="form-errors">{errors.apt}</small> : null}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-12">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Landmark</Form.Label>
                                            <Form.Control as="textarea" rows={1}
                                                type="text" name="landmark" placeholder="nearby landmarks"
                                                value={values.landmark} onChange={handleChange} onBlur={handleBlur}
                                                className={errors.landmark && touched.landmark ? "form-errors" : ""} />
                                            {errors.landmark && touched.landmark ? <small className="form-errors">{errors.landmark}</small> : null}
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12 ">
                                        <Form.Group className="mb-3">
                                            <Form.Check
                                                type="checkbox" name="workAddress" label="Work Address"
                                                value={values.workAddress} onChange={handleChange} onBlur={handleBlur}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="divider-narrow">
                                        <hr className="divider-line" />
                                    </div>
                                </Row>
                                <Row>
                                    <ReCAPTCHA
                                        sitekey="6Le6L8YpAAAAAM2b9bVIv2X6GrSIBJ2DbSjgoT0I"
                                        onChange={onChangeReCAPTCHA}
                                    />
                                </Row>
                                <br/>
                                <Row>
                                    <Form.Group className="mb-3">
                                        <Form.Check
                                            type="checkbox" name="terms" label="Agree to terms and conditions"
                                            value={values.terms} onChange={handleChange} onBlur={handleBlur}
                                            isInvalid={!!errors.terms && touched.terms} feedback={errors.terms} feedbackType="invalid"
                                        />
                                    </Form.Group>
                                </Row>
                                {
                                    userExists === null ?
                                        <></> :
                                        <Row>
                                            <small className="form-errors">{userExists}</small>
                                        </Row>
                                }
                                <Row>
                                    <Button type="submit" id="btn-pri-top-left" disabled={isLoading}>
                                        SIGN UP
                                    </Button>
                                </Row>
                                <Row className="go-to-login">
                                    <p>Already have an account?
                                        <button className="link-btn" >LOG IN</button>
                                    </p>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                    <br />

                </Col>
                <Col className="col-12 col-lg-4">
                    <img alt="sneaker" src={shoesGif} width={350} />
                </Col>
            </Row>
        </Container>
    )
}

export default SignUp
