//import './App.css';
import './App.scss';
import './AdminBoard.scss'
import Favicon from "react-favicon";
import tabIcon from './assets/logos/Logo-tiny.png';

import { BrowserRouter, Routes, Route } from 'react-router-dom'
//import { ShopContextProvider } from './context/ShopContext';
import { UserContextProvider } from './context/UserContext';
//import { ProductsContextProvider } from './context/ProductsContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// components
import NoticeBanner from './components/NoticeBanner';
import NavbarComponent from './components/NavbarComponent';
import TopScroll from './components/TopScroll';
import Footer from './components/Footer';

// routes
import Homepage from './pages/Homepage'; // --- /
import ProductPage from './pages/ProductPage'; // --- /:id
import AboutUs from './pages/About'; // --- /about
import CartPage from './pages/CartPage' // --- /cart
import ShopProducts from './pages/ShopProducts'; // --- /products
import SignUp from './pages/SignUp'; // --- /signup
import Login from './pages/Login'; // --- /login
import Checkout from './pages/CheckoutPage'; // --- /checkout
import OrderFeedback from './pages/OrderFeedback'; // --- /checkout/completed
import UserDetails from './pages/UserDetails'; // --- /user/:id
import PoliciesAccordion from './pages/Policies';
import PrivacyPolicy from './pages/PrivacyPolicy';
//import StayTuned from './pages/StayTuned';
//import UnderConstruction from './pages/UnderConstruction';
import { CartContextProvider } from './context/CartContext';
import AdminBoardHome from './pages/AdminBoardHome';
import AddNewProductForm from './admin-dashboard/NewProductForm';
import AddNewCategoryForm from './admin-dashboard/NewCategoryForm';
import AddNewClientForm from './admin-dashboard/NewHappyClientForm';
import AddNewReviewForm from './admin-dashboard/NewReviewForm';




function App() {
  return (
    <div  className="App">
      <Favicon url={tabIcon}/>
      <NoticeBanner />
      <CartContextProvider>
        <UserContextProvider>
            <BrowserRouter>
              <TopScroll>
                <NavbarComponent />
                <Routes>
                  <Route path='/' element={<Homepage />} />
                  <Route path='/about' element={<AboutUs/>} />
                  <Route path='/policies' element={<PoliciesAccordion/>} />
                  <Route path='/privay-policy' element={<PrivacyPolicy/>} />
                  <Route path='/cart' element={<CartPage/>} />
                  <Route path='/checkout' element={ <Checkout/>} />
                  <Route path='/checkout/completed' element={<OrderFeedback />} />
                  <Route path='/products' element={<ShopProducts/>} />
                  <Route path='/products/:filter' element={<ShopProducts/>} />
                  <Route path='/:id' element={<ProductPage/>} />
                  <Route path='/signup' element={<SignUp />} />
                  <Route path='/login' element={<Login />} />
                  <Route path='/user/:id' element={<UserDetails />}/>
                  <Route path='/admin/homepage' element={<AdminBoardHome />} />
                  <Route path='/admin/homepage/add-new-product' element={<AddNewProductForm />}/>
                  <Route path='/admin/homepage/add-new-category' element={<AddNewCategoryForm />}/>
                  <Route path='/admin/homepage/add-new-happy-client' element={<AddNewClientForm />}/>
                  <Route path='/admin/homepage/add-new-review' element={<AddNewReviewForm />}/>
                </Routes>
                <Footer/>
              </TopScroll>
            </BrowserRouter>
        </UserContextProvider>
      </CartContextProvider>
    </div>
  );
}

export default App;
