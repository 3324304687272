import React from "react";

import { useAuthContext } from "../customHooks/useAuthContext";
import { useLogin } from "../customHooks/useLogin";

import { NEW_BASE_URL } from "../constants/serv";

import { SHIPPING } from '../shippingData'

import { Col, Row, Form, InputGroup, Button, Modal } from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';

import egyFlag from '../assets/images/egypt.png'


const UpdateUserInfoForm = (props) => {

    var  user = JSON.parse(localStorage.getItem('user'));
    const {_id, name, email, phoneNo, city, address, building, apt, floorNo, landmark, workAddress } = user
    const { login } = useLogin()

    const phoneNoRegExp = /^(\+201|01|00201)[0-2,5]{1}[0-9]{8}/g

    let governorates = []
    governorates = SHIPPING.map(o => o.region)

    const SignupSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
        phoneNo: Yup.string().matches(phoneNoRegExp, 'Invalid phoneNo number').max(14, 'Invalid phoneNo number'),
        email: Yup.string().email('Invalid email'),
        city: Yup.string().oneOf(governorates, 'incorrect city value'),
        address: Yup.string(),
        building: Yup.string(),
        floorNo: Yup.string(),
        apt: Yup.string(),
        landmark: Yup.string(),
        workAddress: Yup.string(),
        password: Yup.string().min(8, 'Password must be 8 charachters minimum').required('Required')
    })

    const handleSubmit = async(e) => {
        const {name, phoneNo, password, email, city, address, building, floorNo, apt, landmark, workAddress } = e
        const res = await fetch(`${NEW_BASE_URL}/api/update_profile`, {
            method: 'POST',
            body: JSON.stringify({name, phoneNo, email, city, address, building, floorNo, apt, landmark, workAddress }),
            headers: { 'Content-Type': 'application/json','Accept': 'application/json',  'Authorization': 'Bearer 4Mwoo8jGwIzfeP4o2PSfCqIbyccDUMsjSDYODQQk3c1d61e3'}
        })

        await res.json()

        if(res.ok) {
            //console.log(jsonData)
            const loginData = {email, password}
            const loginRes = await login(loginData)
            if(loginRes) {
                props.onHide()
            }
        }
    }


    return (
        <Modal {...props}  className="modal-container">
            <Modal.Header closeButton>
                <Modal.Title>EDIT PROFILE INFO</Modal.Title>
            </Modal.Header>
                <Modal.Body>
                <Formik
                initialValues={{
                    name:name, phoneNo: phoneNo, email: email, city: city,
                    address: address, building: building, floorNo: floorNo,
                    apt: apt, landmark: landmark, workAddress: workAddress,
                }}
                validationSchema={SignupSchema}
                onSubmit={ values => {
                    handleSubmit(values)
                }}>

                {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col className='col-12 col-lg-6'>
                            <Form.Group className="mb-3" controlId="nameId">
                                <Form.Control
                                    type="text" name="name"
                                    value={values.name} onChange={handleChange} onBlur={handleBlur}
                                    isInvalid={!!errors.name}
                                    className={errors.name ? "form-errors" : "form-valid"}/>
                                {errors.name ? <small className="form-errors">{errors.name}</small> : null }
                            </Form.Group>
                        </Col>
                        <Col className='col-12 col-lg-6'>
                            <Form.Group className="mb-3" controlId="phoneNoNoId">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                        <img alt='egypt' src={egyFlag} width={20}/>
                                    </InputGroup.Text>
                                    <Form.Control
                                        name="phoneNo" value={values.phoneNo}
                                        onChange={handleChange} onBlur={handleBlur}
                                        isInvalid={!!errors.phoneNo}
                                        className={errors.phoneNo ? "form-errors" : "form-valid"}/>
                                </InputGroup>
                                {errors.phoneNo ? <small className="form-errors">{errors.phoneNo}</small> : null }
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='col-12 col-lg-6'>
                            <Form.Group className="mb-3" controlId="emailId">
                                <Form.Control
                                    type="email" name="email"
                                    value={values.email} onChange={handleChange} onBlur={handleBlur}
                                    isInvalid={!!errors.email}
                                    className={errors.email ? "form-errors" : ""}
                                />
                                { errors.email ? <small className="form-errors">{errors.email}</small> : null }
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col className='col-12 col-lg-4'>
                            <Form.Group className="mb-3" controlId="AddressId">
                                <Form.Select
                                    type="text" name="city"
                                    value={values.city} onChange={handleChange} onBlur={handleBlur}
                                    isInvalid={!!errors.city}
                                    className={errors.city ? "form-errors" : ""}
                                >
                                    <option>choose city</option>
                                    {SHIPPING.map((o) => (
                                        <option key={o.id}>{o.region}</option>
                                    ))}
                                </Form.Select>
                                {errors.city ? <small className="form-errors">{errors.city}</small> : null }
                            </Form.Group>
                        </Col>
                        <Col className='col-12 col-lg-8'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Control
                                    type="text" name="address"
                                    value={values.address} onChange={handleChange} onBlur={handleBlur}
                                    isInvalid={!!errors.address}
                                    className={errors.address ? "form-errors" : ""}
                                />
                                {errors.address && touched.address ? <small className="form-errors">{errors.address}</small> : null }
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='col-4'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                <Form.Control
                                    type="text" name="building" placeholder="Building no."
                                    value={values.building} onChange={handleChange} onBlur={handleBlur}
                                    isInvalid={errors.building}
                                    className={errors.building ? "form-errors" : ""}
                                />
                                {errors.building ? <small className="form-errors">{errors.building}</small> : null }
                            </Form.Group>
                        </Col>
                        <Col className='col-4'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                                <Form.Control
                                    type="text" name="floorNo" placeholder="floorNo no."
                                    value={values.floorNo} onChange={handleChange} onBlur={handleBlur}
                                    className={errors.floorNo ? "form-errors" : ""}
                                />
                                {errors.floorNo ? <small className="form-errors">{errors.floorNo}</small> : null }
                            </Form.Group>
                        </Col>
                        <Col className='col-4'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput7">
                                <Form.Control
                                    type="text" name="apt"  placeholder="Apt"
                                    value={values.apt} onChange={handleChange} onBlur={handleBlur}
                                    className={errors.apt ? "form-errors" : ""}
                                />
                                {errors.apt ? <small className="form-errors">{errors.apt}</small> : null }
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Control as="textarea" rows={1}
                                    type="text" name="landmark"  placeholder="nearby landmarks"
                                    value={values.landmark} onChange={handleChange} onBlur={handleBlur}
                                    className={errors.landmark ? "form-errors" : ""}/>
                                    {errors.landmark ? <small className="form-errors">{errors.landmark}</small> : null }
                            </Form.Group>
                        </Col>
                        <Col className="col-12 ">
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="checkbox" name="workAddress" label="Work Address"
                                    value={values.workAddress} onChange={handleChange} onBlur={handleBlur}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="">
                            <Form.Group>
                                <Form.Label htmlFor="inputPassword5">Please add your password to confirm it's you</Form.Label>
                                <Form.Control
                                    type="password" name="password" id="inputPassword5"
                                    aria-describedby="passwordHelpBlock" placeholder="password"
                                    value={values.password} onChange={handleChange} onBlur={handleBlur}
                                    isInvalid={!!errors.password && !touched.password}
                                    className={errors.password && touched.password ? "form-errors" : ""}
                                />
                                {errors.password && touched.password ? <small className="form-errors">{errors.password}</small> : null }
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            <Button type="submit" id="btn-pri-top-left">
                                Update my info
                            </Button>
                        </Col>
                    </Row>
                </Form>
                )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default UpdateUserInfoForm
