import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "./useAuthContext";

import { NEW_BASE_URL } from "../constants/serv";

export const useLogin = () => {

    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    // errors backend validation
    const [emailInvalid, setEmailInvalid] = useState(null)
    const [passwordInvalid, setPasswordInvalid] = useState(null)

    const { dispatch } = useAuthContext()

    const navigate = useNavigate()

    const login = async(data) => {
        const {email, password} = data
        setError(null)
        setIsLoading(true)

        const res = await fetch(`${NEW_BASE_URL}/api/login`, {
            method: 'POST',
            body: JSON.stringify({email, password}),
            headers: { 'Content-Type': 'application/json'}
        })

        const jsonData = await res.json()

        console.log(jsonData.user);

        if(!res.ok) {
            setIsLoading(false)
            setError(jsonData.error)
        }

        if(jsonData.error) {
            if(jsonData.error.includes('incorrect email')) {
                setEmailInvalid('Incorrect email. Please enter the correct email address')
            } else if(jsonData.error.includes('incorrect password')) {
                setPasswordInvalid('Incorrect password. Please enter the correct password')
            } else {
                setEmailInvalid(null)
                setPasswordInvalid(null)
            }

            console.log('---', jsonData.error)
        }

        if(res.ok) {
            // save to local storage
            localStorage.setItem('user', JSON.stringify(jsonData.user))
            localStorage.setItem('_token', JSON.stringify(jsonData.access_token))
            //console.log(jsonData)
            navigate(`/`)

            // update context
            dispatch({type: 'LOGIN', payload: jsonData})
            setIsLoading(false)
        }
    }

    return { login, isLoading, error, emailInvalid, passwordInvalid }
}