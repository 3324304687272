import React, { createContext, useReducer, useEffect } from "react";
import { cartReducer } from "../reducers/cartReducer";

export const CartContext = createContext()

const initialState = []

//const BASE_URL = "https://canalwalkegy-backend.onrender.com"

export const CartContextProvider = (props) => {

    const [ itemsInCart, dispatch ] = useReducer(cartReducer, initialState, () => {
        const localData = localStorage.getItem('itemsInCart');
        console.log(localData)
        return ( localData ? JSON.parse(localData) : initialState )
    });

   // console.log('~~~~~', itemsInCart)

    /*
    useEffect(() => {
        fetch(`${BASE_URL}/api/products`)
        .then(res => res.json())
        .then((data) => {
            dispatch({
                type: 'INITIALIZE_CART',
                payload: {
                    ...initialState,
                    items: data.map(product => ({
                        id: product._id,
                        count: 0,
                        sizeChosen: '',
                        item: {
                            itemName: product.name,
                            itemPrice: product.price,
                            itemDiscount: product.discount,
                            itemGender: product.gender
                        }
                    }))
                }

            })
        })
        .then(() =>
            localStorage.setItem('itemsInCart', JSON.stringify(itemsInCart))
        )
    }, [itemsInCart.cartInitialized]);
    */

    useEffect(() => {
        localStorage.setItem('itemsInCart', JSON.stringify(itemsInCart))
    },[itemsInCart])
    const contextValue = {itemsInCart, dispatch}

    return (
        <CartContext.Provider value={contextValue}>
            {props.children}
        </CartContext.Provider>
    )
}


