import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { Container, Badge, Nav, Navbar, NavDropdown } from "react-bootstrap";


// context
//import { ShopContext } from "../context/ShopContext";
import { useAuthContext } from "../customHooks/useAuthContext";
import { CartContext } from "../context/CartContext";

import { ADMIN_ID } from "../constants/serv";

// logo
import brandLogo from '../assets/logos/Logo-07.png'

// icons
import { cartIcon, barsStagIcon, userIcon } from '../constants/grabIcons'


function NavbarComponent() {

  const { itemsInCart } = useContext(CartContext);
  const { user } = useAuthContext()

  let defaultInCartQty = 0
  itemsInCart.forEach((product) => defaultInCartQty+= Number(product.count))


  // as={Link} to={{pathname: "/products/:featured", state: {filterValue: 'featured'}}}

  return (
  <Navbar collapseOnSelect expand="lg" className="navbar-main" >
    <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="nav-bar-icon">{barsStagIcon}</Navbar.Toggle>
        <Navbar.Brand href="/"><img alt="logo" src={brandLogo} width={160} className="nav-brand"/></Navbar.Brand>
        <Navbar.Collapse>
          <Nav className="nav-items text-start py-2">
            <Nav.Link as={Link} to={{pathname: "/products/:featured"}} state={{filterValue: 'featured'}} >
              NEW ARRIVALS
            </Nav.Link>
            <Nav.Link as={Link} to={{pathname: "/products/:featured"}} state={{filterValue: 'men'}} >
              MEN
            </Nav.Link>
            <Nav.Link as={Link} to={{pathname: "/products/:featured"}} state={{filterValue: 'women'}} >
               WOMEN
            </Nav.Link>
            {/* <NavDropdown title="MEN">
              <NavDropdown.Item  className="nav-drop-item">
                <Nav.Link as={Link} to={{pathname: "/products/:sneakers"}} state={{filterValue: 'men'}}>
                  Sneakers
                </Nav.Link>
              </NavDropdown.Item> */}
              {/* <NavDropdown.Item >
                <Nav.Link as={Link} to={{pathname: "/products/:crease-protectors"}} state={{filterValue: 'crease protectors'}}>
                  Crease Protectors
                </Nav.Link>
              </NavDropdown.Item> */}
            {/* </NavDropdown> */}
            {/* <NavDropdown title="WOMEN" id="basic-nav-dropdown">
              <NavDropdown.Item href="/women/sneakers">
                <Nav.Link as={Link} to={{pathname: "/products/:women"}} state={{filterValue: 'women'}}>
                  Sneakers
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item href="/creaseprotectors">
                <Nav.Link as={Link} to={{pathname: "/products/:crease-protectors"}} state={{filterValue: 'crease protectors'}}>
                  Crease Protectors
                </Nav.Link>
              </NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link href="/about">OUR STORY</Nav.Link>
            { user &&
              <div>
                { user._id === ADMIN_ID ?
                  <Nav.Link as={Link} to={{pathname: '/admin/homepage'}}><strong>ADMIN</strong></Nav.Link>
                :
                  <Nav.Link as={Link} to={{pathname: `/user/:${user._id}`}}>{userIcon} {user.name}</Nav.Link>
                }
                </div>
            }
            { !user &&
              <div>
                <Nav.Link href="/login">{userIcon}</Nav.Link>
              </div>
            }
          </Nav>
        </Navbar.Collapse>
        <Nav className="nav-items navbar-right navbar-nav py-2">
            <Nav.Link href="/cart" className="nav-cart">
              <div>
                {
                  defaultInCartQty === 0 ? <span>{cartIcon}</span>
                  : <span><Badge pill bg='' id="cart-badge">{defaultInCartQty}</Badge>{cartIcon}</span>
                }
              </div>
            </Nav.Link>
        </Nav>
    </Container>
</Navbar>
  );
}

export default NavbarComponent;