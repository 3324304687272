import axios from "axios"
import { useState,useEffect } from "react"
import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap"
import { NEW_BASE_URL } from "../constants/serv"
import { useSKUGenerator } from "../customHooks/useSKUGenerator"
import { useNavigate } from "react-router-dom"

const AddNewProductForm = () => {

    const { generateSKU } = useSKUGenerator()
    const navigate = useNavigate()
    // UI feedback
    const [loading, setLoading] = useState(false)
    const [isApproved, setIsApproved] = useState(false)

    const [avail, setAvail] = useState(true)
    const [feat, setFeat] = useState(false)
    //const [productImagesArr, setProductImagesArr] = useState([{}])
    const [triggerToggle, setTriggerToggle] = useState(true)

    const [categories, setCategories] = useState()

    useEffect(() => {
        const fetchCategories = async () => {
            const res = await fetch(`${NEW_BASE_URL}/api/categories`)
            //console.log(res)
            var json = await res.json()
            json=json.data
            //console.log(json)

            if (res.ok) {
                setCategories(json)
            } else {
                console.log('error occurred', res)
            }
        }

        fetchCategories()
    }, [triggerToggle])

    const [name, setName] = useState("")
    const [price, setPrice] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [brand, setBrand] = useState("")
    const [category, setCategory] = useState("")
    const [gender, setGender] = useState("")
    const [featured, setFeatured] = useState(false)
    const [color, setColor] = useState("")
    const [material, setMaterial] = useState("")
    const [availability, setAvailability] = useState(true)
    const [sizeSheet, setSizeSheet] = useState([
        { size: 36, qty: 0 },
        { size: 37, qty: 0 },
        { size: 38, qty: 0 },
        { size: 39, qty: 0 },
        { size: 40, qty: 0 },
        { size: 41, qty: 0 },
        { size: 42, qty: 0 },
        { size: 43, qty: 0 },
        { size: 44, qty: 0 },
        { size: 45, qty: 0 },
        { size: 46, qty: 0 }
    ])
    const [productImages, setProductImages] = useState([])
    const [previewImages, setPreviewImages] = useState([])
    const [previewVideo, setPreviewVideo] = useState()

    const handleSizeSheet = (size, e) => {
        //console.log(sizeSheet)
        const index = sizeSheet.findIndex(item => item.size === size)
        //console.log(index)
        //console.log(size, e)

        const newSizeArr = sizeSheet.map((item, i) => {
            if (index === i) {
                return { ...item, qty: parseInt(e) }
            } else {
                return item
            }
        })
        //console.log('-----------', newSizeArr)
        setSizeSheet(newSizeArr)
    }

    const handleSaveProduct = (e) => {
        e.preventDefault()
        setLoading(true)

        // preparing the product object to send to db
        if (brand.length === 0) {
            setBrand("NoBrand")
        }
        if (category.length === 0) {
            setCategory("NoCategory")
        }
        if (gender.length === 0) {
            setGender("Genderless")
        }
        if (name.length === 0) {
            setName("NoName")
        }

        // generate SKU
        const data = { category, brand, name, gender }
        const sku = generateSKU(data)

        const saveProduct = () => {

            const formData = new FormData();
            formData.append('name', name)
            formData.append('sku', sku)
            formData.append('brand', brand)
            formData.append('category_id', category)
            formData.append('gender', gender)
            formData.append('price', Number(price))
            formData.append('discount', Number(discount))
            formData.append('featured', featured)
            formData.append('color', color)
            formData.append('material', material)
            formData.append('availability', availability)
            // Append sizeSheet
            formData.append('sizeSheet', JSON.stringify(sizeSheet))
            // Append Product Images
            if(productImages.length > 0) {
              
                for (let i = 0; i < productImages.length; i++) {
                  //  console.log(productImages[i])
                    formData.append('productImages[]', productImages[i])
                }
            } else formData.append('productImages', productImages)

            // Append Preview Images
            if(previewImages.length > 0) {
                for (let i = 0; i < previewImages.length; i++) {
                    formData.append('previewImages[]', previewImages[i])
                }
            } else formData.append('previewImages', previewImages)
            // Append Video
            formData.append('previewVideo[]', previewVideo)
            // for (var pair of formData.entries()) {
            //     console.log(pair[0]+ ', ' + pair[1]); 
            // }
            // post to db
            axios.post(`${NEW_BASE_URL}/api/products/post`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(res => {
                console.log('res', res)
                setLoading(false)
                navigate('/admin/homepage')
                /*
                if (res.status === 200) {
                    setTriggerLoad(!triggerLoad)
                }
                */
            }).catch(err => console.log('err', err))


        }

        saveProduct()
    }

    return (
        <Container className="py-5">
            <Row>
                <Col className='col-12'>
                    <h4><strong>Add New Product</strong></h4>
                </Col>
            </Row>
            <Row>
                <Form encType="multipart/form-data">
                    <Row className="py-1">
                        <strong>Basic Info</strong>
                        <small className="grey tiny-txt">Please fill in all fields</small>
                    </Row>
                    <Row>
                        <Col className="col-12 col-lg-6">
                            <Form.Group className="mb-3">
                                <Form.Control type="text" name="productName" placeholder="Product Name"
                                    onChange={e => setName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="col-6 col-lg-3">
                            <InputGroup className="mb-3">
                                <InputGroup.Text>EGP</InputGroup.Text>
                                <Form.Control aria-label="price" name="price" placeholder="price"
                                    onChange={e => setPrice(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                        <Col className="col-6 col-lg-3">
                            <InputGroup className="mb-3">
                                <InputGroup.Text>EGP</InputGroup.Text>
                                <Form.Control aria-label="discount" name="discount" placeholder="discount"
                                    onChange={e => setDiscount(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-6 col-lg-3">
                            <Form.Group className="mb-3">
                                <Form.Select aria-label="Default select example" name="brand"
                                    onChange={e => setBrand(e.target.value)}
                                >
                                    <option>-- brand</option>
                                    <option value="Adidas">Adidas</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="New Balance">New Balance</option>
                                    <option value="Nike">Nike</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col className="col-6 col-lg-3">
                            <Form.Group className="mb-3">
                                <Form.Select aria-label="Default select example" name="category"
                                    onChange={e => setCategory(e.target.value)}
                                >
                                    <option>-- category</option>
                                    {categories && categories.map((p, i) => (
                                        <option value={p.id}>{p.name}</option>
                                    ))}
  
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col className="col-6 col-lg-3">
                            <Form.Group className="mb-3">
                                <Form.Select aria-label="Default select example" name="gender"
                                    onChange={e => setGender(e.target.value)}
                                >
                                    <option>-- gender</option>
                                    <option value="men">men</option>
                                    <option value="women">women</option>
                                    <option value="unisex">unisex</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col className="col-6 col-lg-3">
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    id="default-checkbox" name="featured"
                                    label="New Arrival" defaultValue={feat}
                                    onChange={e => {
                                        setFeat(!feat)
                                        setFeatured(!feat)
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12 col-lg-3">
                            <Form.Group className="mb-3">
                                <Form.Control type="text" placeholder="color" name="color"
                                    onChange={e => setColor(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col className="col-6 col-lg-3">
                            <Form.Group className="mb-3">
                                <Form.Control type="text" placeholder="material" name="material"
                                    onChange={e => setMaterial(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col className="col-6 col-lg-3">
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    id="default-checkbox" name="availability"
                                    label="Availabile in Stock" defaultValue={avail} defaultChecked={avail}
                                    onChange={e => {
                                        setAvail(!avail)
                                        setAvailability(!avail)
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row className="py-2">
                        <strong>Available Size Sheet</strong>
                        <small className="grey tiny-txt">How many items are available of each size? Default= 0</small>
                    </Row>
                    <div>
                        <Row>
                            <small><strong className="grey">Women</strong></small>
                        </Row>
                        <Row>
                            <Col className="col-4 col-lg-2">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>36</InputGroup.Text>
                                    <Form.Control aria-label="thirty-six" type="number" min={0} placeholder={0}
                                        onChange={e => handleSizeSheet(36, e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col className="col-4 col-lg-2">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>37</InputGroup.Text>
                                    <Form.Control aria-label="thirty-seven" type="number" min={0} placeholder={0}
                                        onChange={e => handleSizeSheet(37, e.target.value)} />
                                </InputGroup>
                            </Col>
                            <Col className="col-4 col-lg-2">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>38</InputGroup.Text>
                                    <Form.Control aria-label="thirty-eight" type="number" min={0} placeholder="0"
                                        onChange={e => handleSizeSheet(38, e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col className="col-4 col-lg-2">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>39</InputGroup.Text>
                                    <Form.Control aria-label="thirty-nine" type="number" min={0} placeholder="0"
                                        onChange={e => handleSizeSheet(39, e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col className="col-4 col-lg-2">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>40</InputGroup.Text>
                                    <Form.Control aria-label="fourty" type="number" min={0} placeholder="0"
                                        onChange={e => handleSizeSheet(40, e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col className="col-4 col-lg-2">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>41</InputGroup.Text>
                                    <Form.Control aria-label="forty-one" type="number" min={0} placeholder="0"
                                        onChange={e => handleSizeSheet(41, e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <small><strong className="grey">Men</strong></small>
                        </Row>
                        <Row>
                            <Col className="col-4 col-lg-2">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>41</InputGroup.Text>
                                    <Form.Control aria-label="fourty" type="number" min={0} placeholder="0"
                                        onChange={e => handleSizeSheet(41, e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col className="col-4 col-lg-2">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>42</InputGroup.Text>
                                    <Form.Control aria-label="fourty-one" type="number" min={0} placeholder="0"
                                        onChange={e => handleSizeSheet(42, e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col className="col-4 col-lg-2">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>43</InputGroup.Text>
                                    <Form.Control aria-label="forty-two" type="number" min={0} placeholder="0"
                                        onChange={e => handleSizeSheet(43, e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col className="col-4 col-lg-2">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>44</InputGroup.Text>
                                    <Form.Control aria-label="fort-three" type="number" min={0} placeholder="0"
                                        onChange={e => handleSizeSheet(44, e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col className="col-4 col-lg-2">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>45</InputGroup.Text>
                                    <Form.Control aria-label="fourty-fout" type="number" min={0} placeholder="0"
                                        onChange={e => handleSizeSheet(45, e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col className="col-4 col-lg-2">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>46</InputGroup.Text>
                                    <Form.Control aria-label="forty-five" type="number" min={0} placeholder="0"
                                        onChange={e => handleSizeSheet(46, e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>
                    <div className="hidden">
                        <Row>
                            <small><strong className="grey">UNISEX - ONE SIZE</strong></small>
                        </Row>
                        <Row>
                            <Col className="col-4 col-lg-2">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>1</InputGroup.Text>
                                    <Form.Control aria-label="fourty" type="number" min={0} placeholder="0"
                                        onChange={e => handleSizeSheet(1, e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>
                    <br />
                    <Row>
                        <strong>Images and Media</strong>
                        <small className="grey tiny-txt">All images must be: .jpg or .png, Dimension are preffered in square, min: (450x450px), max: (700x700px).</small>
                    </Row>
                    <br />
                    <Row>
                        <Col className="col-12 col-lg-4">
                            <>
                                <strong className="grey">Product Images</strong>
                                <Form.Group className="mb-3" >
                                    <Form.Control type="file" multiple name="productImages"
                                        onChange={(e) => {
                                            setProductImages(e.target.files)
                                            console.log(productImages, e.target.files)
                                        }}
                                    />
                                    <small className="grey tiny-txt">6 images maximum</small>

                                </Form.Group>
                            </>
                        </Col>
                        <Col className="col-12 col-lg-4">
                            <>
                                <strong className="grey">Live Photos</strong>
                                <Form.Group className="mb-3">
                                    <Form.Control type="file" multiple name="previewImages"
                                        onChange={(e) => {
                                            setPreviewImages(e.target.files)
                                            console.log(previewImages, e.target.files)
                                        }}
                                    />
                                    <small className="grey tiny-txt">4 images maximum</small>
                                </Form.Group>
                            </>
                        </Col>
                        <Col className="col-12 col-lg-4">
                            <>
                                <strong className="grey">Preview Video</strong>
                                <Form.Group className="mb-3">
                                    <Form.Control type="file" name="previewVideo"
                                        onChange={e => {
                                            setPreviewVideo(e.target.files[0])
                                            console.log(e.target.files[0])
                                        }}
                                    />
                                    <small className="grey tiny-txt">Video must not exceed 20MB</small>
                                </Form.Group>
                            </>
                        </Col>
                    </Row>
                    <div>

                    </div>
                    <div>


                    </div>
                    <Row>
                        <div className="text-end">
                            <Button variant="success" //disabled={loading}
                                onClick={e => handleSaveProduct(e)}>
                                Save Product
                            </Button>
                        </div>
                    </Row>
                </Form>
            </Row>
        </Container>
    )
}

export default AddNewProductForm